import React from 'react';
import Tab from '@material-ui/core/Tab';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        boxShadow: '0 1px 3px 0 #00000029',
        height: theme.spacing(7),
        opacity: 1,
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main,
        },
        '&$selected': {
            color: theme.palette.secondary.main,
        },
        '&:focus': {
            color: theme.palette.secondary.main,
        },
        ...theme.typography.button
    },
    selected: {
        color: theme.palette.secondary.main,
        boxShadow: 'none',
        backgroundColor: theme.palette.grey.blue4
    }
})

@withStyles(styles)
class UtfTab extends React.Component {

    render() {
        const {children, ...restProps} = this.props;
        return (
            <Tab {...restProps}>
                {children}
            </Tab>);
    }
}

export default UtfTab;