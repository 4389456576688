import {action, computed, observable} from 'mobx';

import {MENU_WIDTH, SECTION_X_SEP, SIDENAV_WIDTH} from '../conf/ui_constants';
import {debounce} from '../shared/utils';

const MODELS = [
    'is_substation_model_open',
    'is_filteroptions_open',
    'is_subsummary_open'
]

class UiStore {
    @observable is_sidenav_open = true;
    @observable is_substation_model_open = false;
    @observable is_filteroptions_open = false;
    @observable is_subsummary_open = false;
    @observable is_editfilters_open = false;
    @observable is_subdetail_open = false;
    @observable theme = 'default';
    @observable current_subdetail_tab = 'overview';
    @observable current_properties_tab = 'trends';
    @observable banners = [];

    @observable windowWidth = null;
    @observable screenWindow = null;


    constructor(parent) {
        this.parent = parent;

        this.modelToggle = this.modelToggle.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
        this.toggleSubstations = this.toggleSubstations.bind(this);
        this.toggleSubsummary = this.toggleSubsummary.bind(this);
        this.closeSubsummary = this.closeSubsummary.bind(this);
        this.toggleSubDetail = this.toggleSubDetail.bind(this);
        this.openSubDetail = this.openSubDetail.bind(this);
        this.closeSubDetail = this.closeSubDetail.bind(this);
        this.addBanner = this.addBanner.bind(this);
        this.removeBanner = this.removeBanner.bind(this);
        this.openEditfilters = this.openEditfilters.bind(this);
        this.closeEditFilters = this.closeEditFilters.bind(this);
        this.setWindowWidth = this.setWindowWidth.bind(this);
        this.changeSubdetailTab = this.changeSubdetailTab.bind(this);

        this.setWindow();
    }

    /* views */

    @computed get main_sidebar_width() {
        return MENU_WIDTH;
    }

    @computed get max_drawer_width() {
        return this.windowWidth - (this.is_sidenav_open ? SIDENAV_WIDTH : MENU_WIDTH) - SECTION_X_SEP;
    }

    @computed get max_section_width() {
        return this.windowWidth - (this.is_sidenav_open ? SIDENAV_WIDTH : MENU_WIDTH) - 2 * SECTION_X_SEP;
    }

    /* actions  */

    setWindow() {
        if (typeof window === 'object') {
            this.screenWindow = window
            this.handleWindowWidthChange()
            this.screenWindow.addEventListener("resize", this.handleWindowWidthChange)
        }
    }

    handleWindowWidthChange = debounce(() => {
        const width = this.screenWindow.innerWidth
        this.setWindowWidth(width)
    }, 100)


    @action.bound
    setWindowWidth(width) {
        this.windowWidth = width
        return this.windowWidth
    }

    @action.bound
    changeLocale(newLocale){
        this.locale = newLocale
    }

    @action.bound
    modelToggle(current) {
        this[current] = !this[current];
        if (this[current]) {
            for (let model of MODELS) {
                if (model !== current) {
                    this[model] = false;
                }
            }
        }
    }

    @action.bound
    toggleSubstations() {
        let is_currently_open = this.is_substation_model_open || this.is_subsummary_open || this.is_subdetail_open;
        if (is_currently_open) {
            this.is_substation_model_open = false;
            this.is_subsummary_open = false;
            this.is_subdetail_open = false;
        } else {
            if (this.is_filteroptions_open) {
                this.is_filteroptions_open = false;
            }
            this.is_substation_model_open = true;
        }
    }

    @action.bound
    goToExploreFromSummary() {
        this.is_subsummary_open = false;
        this.is_substation_model_open = true;
    }

    @action.bound
    toggleFilters() {
        this.modelToggle('is_filteroptions_open')
    }

    @action.bound
    toggleSubsummary() {
        this.modelToggle('is_subsummary_open')
    }

    @action.bound
    openSubsummary() {
        this.is_subsummary_open = true;
        for (let model of MODELS) {
            if (model !== 'is_subsummary_open') {
                this[model] = false;
            }
        }
    }

    @action.bound
    closeSubsummary() {
        this.is_subsummary_open = false;
    }

    @action.bound
    addBanner(b) {
        this.banners.push(b);
    }

    @action.bound
    removeBanner(index) {
        if (index) {
            this.banners = this.banners.filter(k => k.id !== index)
        } else {
            this.banners.shift()
        }
    }

    @action.bound
    openEditfilters() {
        this.is_editfilters_open = true;
    }

    @action.bound
    closeEditFilters() {
        this.is_editfilters_open = false;
    }

    @action.bound
    toggleSubDetail() {
        this.modelToggle('is_subdetail_open')
    }

    @action.bound
    openSubDetail() {
        this.current_subdetail_tab = "overview";
        this.is_subdetail_open = true;
    }

    @action.bound
    closeSubDetail() {
        this.is_subdetail_open = false;
    }

    @action.bound
    changeSubdetailTab(value) {
        this.current_subdetail_tab = value;
    }

    @action.bound
    openSideNav() {
        this.is_sidenav_open = true;
    }

    @action.bound
    closeSideNav() {
        this.is_sidenav_open = false;
    }

    @action.bound
    changePropertiesTab(value) {
        this.current_properties_tab = value;
    }
}

export default UiStore;
