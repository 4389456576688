import {observable, action} from 'mobx';
import Messages from '../messages';
const LOCALE = "Locale";

class LocaleStore{
    @observable locale = "";

    constructor(parent, defaultLocale){
        this.parent = parent;
        if(typeof(Storage) !== "undefined"){
            const storedLocale = localStorage.getItem(LOCALE);
            if(storedLocale && storedLocale in Messages){
                this.value = storedLocale;
            }else{
                this.value = defaultLocale;
            }
        }
    }

    get value(){
        return this.locale
    }

    set value(value) {
        if (typeof(Storage) !== "undefined") {
          localStorage.setItem(LOCALE, value);
        }
        this.locale = value;
    }

    @action.bound
    switch(){
        this.value = this.value === "sv-SE"?"en-US":"sv-SE"
    }
}

export default LocaleStore;