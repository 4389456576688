// src/App.js

import React from "react";
import { Provider } from 'mobx-react';
import { toJS } from 'mobx';
import { HistoryAdapter } from 'mobx-state-router';
import CssBaseline from '@material-ui/core/CssBaseline';

import rootStore from './stores/root_store';
import Router from './components/routing/Router';
import history from './history';
// for dev purposes
import * as Konf from './conf/constants';
import LocaleProvider from './localeProvider';
import ThemeProvider from './themeProvider';

const hAdaptor = new HistoryAdapter(rootStore.routerStore, history);
hAdaptor.observeRouterStateChanges();

if (process.env.NODE_ENV === "development") {
    window.rs = rootStore;
    window.toJS = toJS;
    window.konf = Konf;
    window.openOld = function () {
        window.open(`http://localhost:3001/?network_id=open_&access_token=Bearer ${rootStore.session.token}`, "_blank")
    }
}

class App extends React.Component {

    async componentDidMount() {
        await rootStore.startup()
    }

    render() {
        return (
            <Provider
                rootStore={rootStore}
                session={rootStore.session}
                ui={rootStore.ui}
                properties={rootStore.properties}
                sub={rootStore.sub}
                locale={rootStore.locale}
            >
                <LocaleProvider>
                    <ThemeProvider >
                        <Router />
                        <CssBaseline />
                    </ThemeProvider>
                </LocaleProvider>
            </Provider>

        );
    }
}

export default App;
