import React from 'react';
import {observer} from 'mobx-react';
import {Typography} from '@material-ui/core';
import {darken, withStyles} from '@material-ui/core/styles';
import ResourceLine from './ResourceLine';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
        minHeight: theme.spacing(31)
    },
    root__haveData: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
    },
    '@keyframes pulseEffect': {
        '0%': {
            backgroundColor: theme.palette.common.white,
        },
        '50%': {
            backgroundColor: darken(theme.palette.common.white, 0.05),
        },
        '100%': {
            backgroundColor: theme.palette.common.white,
        }
    },
    root__notHaveData: {
        animationName: '$pulseEffect',
        animationDuration: '3s',
        animationIterationCount: 'infinite'
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: theme.palette.grey[500],
        marginBottom: theme.spacing(2),
    },
    midSection: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        flexGrow: 1,
    },
})


@withStyles(styles)
@observer
class TopFiveResource extends React.Component {
    render() {
        const {
            classes, haveData = false,
            title,
            subtitle,
            list = [],
            idAttr,
            ...restProps
        } = this.props;
        return (
            <div
                className={clsx(classes.root, haveData && classes.root__haveData, !haveData && classes.root__notHaveData)}>
                {
                    haveData && (
                        <>
                            <div className={classes.titleBar}>
                                <Typography variant="h6" color="textPrimary">
                                    {title}
                                </Typography>
                                <Typography variant="h6" color="inherit">
                                    {subtitle}
                                </Typography>
                            </div>
                            <div className={classes.midSection}>
                                {list.map(
                                    reading => (<ResourceLine key={reading[idAttr]} reading={reading}
                                                              idAttr={idAttr} {...restProps} />)
                                )}
                            </div>
                        </>)
                }
            </div>
        )
    }
}

export default TopFiveResource;