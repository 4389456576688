import React, {Component} from 'react'
import {observer} from 'mobx-react';
import clsx from 'clsx';

import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    cell: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    arrowUp: {
        width: 0,
        height: 0,
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderBottom: '4px solid',
        marginBottom: theme.spacing(.5)
    },
    arrowDown: {
        width: 0,
        height: 0,
        borderLeft: '4px solid transparent',
        borderRight: '4px solid transparent',
        borderTop: '4px solid'
    },
    color__active: {
        borderBottomColor: theme.palette.secondary.main,
        borderTopColor: theme.palette.secondary.main
    },
    color__inactive: {
        borderBottomColor: theme.palette.primary.main,
        borderTopColor: theme.palette.primary.main,
    },
});

@withStyles(styles)
@observer
class TableSortLabel extends Component {
    sortUp = () => {
        const {store, colId} = this.props;
        store.changeSortDirection(colId, "asc")
    }
    sortDown = () => {
        const {store, colId} = this.props;
        store.changeSortDirection(colId, "desc")
    }

    render() {
        const {store, classes, colId} = this.props;
        const up_active = store.sortId === colId && store.sortDirection === "asc" ? classes.color__active : classes.color__inactive;
        const down_active = store.sortId === colId && store.sortDirection === "desc" ? classes.color__active : classes.color__inactive;
        return (
            <div className={classes.cell}>
                <div
                    className={clsx(classes.arrowUp, up_active)}
                    onClick={this.sortUp}
                />
                <div
                    onClick={this.sortDown}
                    className={clsx(classes.arrowDown, down_active)}
                />
            </div>
        )
    }
}

export default TableSortLabel;