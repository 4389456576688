import React from 'react';
import {inject, observer} from "mobx-react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "../../components/ui/UtfTableHead";
import {withStyles} from '@material-ui/core/styles';
import TableBody from "@material-ui/core/TableBody";
import TableRow from "../../components/ui/UtfTableRow";
import RowCell from "../../components/ui/RowCell";
import {Typography} from "@material-ui/core";
import DeviationValue from "../../components/analytics/DeviationValue";
import TableContainer from "@material-ui/core/TableContainer";
import {TABLE_COLUMNS} from "../DashPage/constants";
import GraphIcon from "../../icons/Graph";
import ButtonDropdown from "../../components/ui/ButtonDropDown";
import CentrallyAlignedRow from "../../components/layout/CentrallyAlignedRow";
import {DISABLED_DURATION,DISABLED_COMPARE} from './Constants';
import {PROP_DUR_OPTIONS,PROP_CMP_OPTIONS} from "../../conf/constants";
import NumericalCell from "../../components/analytics/NumericalCell";
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    graphicon: {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey.blue50
    }
})

@inject('sub',)
@withStyles(styles)
@observer
class SubTable extends React.Component {
    render() {
        const {sub, classes} = this.props;
        const row = sub.detail;

        return (
            <>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead store={sub.detail} columns={TABLE_COLUMNS}/>
                        <TableBody>
                            <TableRow key={row.id}>
                                <RowCell column={TABLE_COLUMNS[0]}
                                             current={row}
                                    >
                                        <Typography variant="body2" color="primary">{row.address}</Typography>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[1]} current={row}>
                                        <Typography variant="body2">{row.city}</Typography>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[2]} current={row}>
                                        <NumericalCell row={row} colname="normal_year"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[3]} current={row}>
                                        <NumericalCell row={row} colname="energy"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[4]} current={row}>
                                        <DeviationValue row={row} colname="energy_deviation"/>
                                    </RowCell>
                                <RowCell column={TABLE_COLUMNS[5]} current={row}>
                                        <NumericalCell row={row} colname="power"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[6]} current={row}>
                                        <DeviationValue row={row} colname="power_deviation"/>
                                    </RowCell>
                                <RowCell column={TABLE_COLUMNS[7]} current={row}>
                                        <NumericalCell row={row} colname="return_temp"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[8]} current={row}>
                                        <DeviationValue row={row} colname="return_temp_deviation"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[9]} current={row}>
                                        <NumericalCell row={row} colname="flow"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[10]} current={row}>
                                        <DeviationValue row={row} colname="flow_deviation"/>
                                    </RowCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <CentrallyAlignedRow my={2} justifyContent="flex-end">
                    <span className={classes.graphicon}><GraphIcon/></span>
                    <span style={{marginLeft: 8}}>
                            <ButtonDropdown
                                options={PROP_DUR_OPTIONS}
                                selectedValue="latest_month"
                                disabled={DISABLED_DURATION}
                            />
                        </span>
                    <span style={{marginLeft: 8}}>
                            <ButtonDropdown
                                options={PROP_CMP_OPTIONS}
                                selectedValue="expected"
                                optionPrefix={<FormattedMessage id="compare_with"/>}
                                disabled={DISABLED_COMPARE}
                            />
                        </span>
                </CentrallyAlignedRow>
            </>
        );
    }
}

export default SubTable;