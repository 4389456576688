import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Graph(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M23.625 19.5H1.5V3.375A.375.375 0 001.125 3h-.75A.375.375 0 000 3.375V20.25a.75.75 0 00.75.75h22.875a.375.375 0 00.375-.375v-.75a.375.375 0 00-.375-.375zM4.61 12.33a.375.375 0 000 .53l.53.53a.375.375 0 00.53 0l3.407-3.375 3.97 3.97a.75.75 0 001.061 0L18.492 9.6l2.064 2.064a1.114 1.114 0 00.8.336 1.138 1.138 0 001.144-1.141v-5.6a.759.759 0 00-.759-.759h-5.6a1.138 1.138 0 00-.8 1.943l2.1 2.1-3.854 3.854-3.976-3.971a.75.75 0 00-1.058 0zM17.014 6H21v3.986z"/>
        </SvgIcon>
    )
}
