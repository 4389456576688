import numeral from 'numeral';
import startOfMonth from "date-fns/startOfMonth";
import startOfYear from "date-fns/startOfYear";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes";
import endOfMonth from "date-fns/endOfMonth";
import endOfYear from "date-fns/endOfYear";
import endOfDay from 'date-fns/endOfDay';
import startOfDay from  'date-fns/startOfDay';

import addTime from 'date-fns/add';

export function range(start, stop, step) {
    var result = [];
    if (typeof stop == 'undefined') {
        stop = start;
        start = 0;
    }
    if (typeof step == 'undefined') {
        step = 1;
    }
    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
        return [];
    }
    for (var i = start; step > 0 ? i < stop : i > stop; i += step) {
        result.push(i);
    }
    return result;
}


export const formatNumber = (number) => {
    if (number >= 100 || number <= -100) {
        return numeral(number).format('0 ,');
    } else if ((number >= 10 && number <= 99) || (number <= -10 && number >= -99)) {
        return numeral(number).format('0.0');
    } else if ((number >= 1 && number <= 9) || (number <= -1 && number >= -9)) {
        return numeral(number).format('0.00');
    } else if ((number < 1 && number > 0) || (number > -1 && number < 0)) {
        return numeral(number).format('0.000');
    } else {
        return numeral(number).format('0.0');
    }
};

export function formatBlkYM(year, month) {
    /* block follow 1-12 months whereas js 0-11 */
    if (month) {
        month = (parseInt(month) + 1).toString().padStart(2, '0')
        return `${year}-${month}`
    } else {
        return `${year}`
    }
}

export function toBlkMonth(month) {
    return (parseInt(month) + 1).toString().padStart(2, '0')
}

export function formatBlkDate(date) {
    /* block follow 1-12 months whereas js 0-11 */
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${year}-${month}`
}

const endResolutionFns = {
    day:(k)=>{ return addTime(endOfDay(k),{seconds:1})},
    month:(k)=>{ return addTime(endOfMonth(k),{seconds:1})},
    year:(k)=>{ return addTime(endOfYear(k),{seconds:1})},
}

const startResolutionFns = {
    day:(k)=>{ return addTime(startOfDay(k),{seconds:1})},
    month:(k)=>{ return addTime(startOfMonth(k),{seconds:1})},
    year:(k)=>{ return addTime(startOfYear(k),{seconds:1})}
}

export function getBoundary({pos,res="day",year,month=5,day=14,idate}){
    /*
    endOfHour = hour end local timezone.
    endOfDay = hour end like day end timezone
    endOfToday
    endOfMonth
    endOfYear
    */

    idate = idate||new Date(year,month,day);
    if(pos === "end"){
        return endResolutionFns[res](idate)
    }else{
        return startResolutionFns[res](idate)
    }
}
window.getB = getBoundary

export function dateFromYM(bound, year, month) {
    let res = null;
    if (bound === 'start') {
        if (month) {
            res = startOfMonth(new Date(year, month, 15))
        } else {
            res = startOfYear(new Date(year, 4, 15))
        }
    } else if (bound === 'end') {
        if (month) {
            res = roundToNearestMinutes(endOfMonth(new Date(year, month, 15)))
        } else {
            res = roundToNearestMinutes(endOfYear(new Date(year, 4)))
        }
    }
    return res;
}

export function formatDateAPI(date) {
    return new Date(date).toISOString().slice(0, -5)
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}