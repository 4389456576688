import React, {Component} from 'react';
import {observer} from 'mobx-react';
import TableSortLabel from './TableSortLabel';
import GraphIcon from '../../icons/Graph';
import TableCell from '@material-ui/core/TableCell';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl'
import Tooltip from "@material-ui/core/Tooltip";
import Box from '@material-ui/core/Box';

const styles = theme => ({
    root: {
        border: 'solid 1px #dbe0e4'
    },
    cell: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#747b80',
        maxWidth:100
    },
    graph: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing(1),
    },
    text:{
        marginLeft:theme.spacing(1)
    }
});

@withStyles(styles)
@observer
class UtfTableCell extends Component {
    render() {
        const {store, current, classes} = this.props;
        return (
            <TableCell
                align={current.numeric ? 'right' : 'left'}
                //padding={current.disablePadding ? 'none' : 'default'}
                className={classes.root}
            >
                <Tooltip title={<FormattedMessage id={current.id}/>}>
                    <Box className={classes.cell}>
                    {current.sortEnabled
                    && <TableSortLabel
                        store={store}
                        colId={current.id}
                    />
                    }
                    {
                        current.graph
                        && <div className={classes.graph}><GraphIcon/></div>
                    }

                    {!current.hideTitle
                    &&
                        <>
                        <Typography variant="h6" className={classes.text} noWrap={true}>
                            <FormattedMessage id={current.id}/>
                        </Typography>
                        <Typography variant="h6" >
                            {current.unit && `(${current.unit})`}
                        </Typography>
                    </>
                    }
                    </Box>
                </Tooltip>
            </TableCell>
        );
    }
}

export default UtfTableCell