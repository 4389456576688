export const TABLE_COLUMNS = [
    {
        id: 'address',
        label: 'Address',

    }, {
        id: 'city',
        label: 'City',
        numeric: true,
    }, {
        id: 'normal_year',
        label: 'Normal Year Consumption',
        unit: 'kWh',
        numeric: true,
        sortEnabled: true
    }, {
        id: 'energy',
        unit: 'kWh',
        numeric: true,
        graph: false,
        sortEnabled: true,
    },
    {
        id: 'energy_deviation',
        unit: 'kWh',
        numeric: true,
        graph: true,
        sortEnabled: true,
        hideTitle:true,
        disablePadding:true
    },
    {
        id: 'power',
        unit: 'kW',
        numeric: true,
        graph: false,
        sortEnabled: true,
    },
    {
        id: 'power_deviation',
        unit: 'kW',
        numeric: true,
        graph: true,
        sortEnabled: true,
        hideTitle:true,
        disablePadding:true
    },
    {
        id: 'return_temp',
        unit: '°C',
        numeric: true,
        graph: false,
        sortEnabled: true
    },
    {
        id: 'return_temp_deviation',
        unit: '°C',
        numeric: true,
        graph: true,
        sortEnabled: true,
        hideTitle:true,
        disablePadding:true
    },
    {
        id: 'flow',
        unit: 'm³',
        numeric: true,
        graph: false,
        sortEnabled: true
    },
    {
        id: 'flow_deviation',
        unit: 'm³',
        numeric: true,
        graph: true,
        sortEnabled: true,
        hideTitle:true,
        disablePadding:true
    },
]