import React from 'react';

import {Typography} from '@material-ui/core';
import {darken, withStyles} from '@material-ui/core/styles';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
        minHeight: theme.spacing(31)
    },
    root__haveData: {
        padding: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
    },
    '@keyframes pulseEffect': {
        '0%': {
            backgroundColor: theme.palette.common.white,
        },
        '50%': {
            backgroundColor: darken(theme.palette.common.white, 0.05),
        },
        '100%': {
            backgroundColor: theme.palette.common.white,
        }
    },
    root__notHaveData: {
        animationName: '$pulseEffect',
        animationDuration: '3s',
        animationIterationCount: 'infinite'
    },
    titleBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: theme.palette.grey[500]
    },
    midSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    midText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    bottomSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    previousSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    pctSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    updownIcon: {
        marginTop: '-4px'
    }
})

@withStyles(styles)
class KpiCard extends React.Component {
    render() {
        const {
            classes, haveData = false,
            title,
            subtitle,
            kpiValue,
            kpiUnit,
            comparisonText,
            comparisonValue,
            pctValue
        } = this.props;
        return (
            <div
                className={clsx(classes.root, haveData && classes.root__haveData, !haveData && classes.root__notHaveData)}>
                {
                    haveData && (
                        <>
                            <div className={classes.titleBar}>
                                <Typography variant="h6" color="textPrimary">
                                    {title}
                                </Typography>
                                <Typography variant="h6" color="inherit">
                                    {subtitle}
                                </Typography>
                            </div>
                            <div className={classes.midSection}>
                                <div className={classes.midText}>
                                    <Typography component="span" variant="h1" color="secondary">
                                        {kpiValue}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        color="secondary"
                                        style={{lineHeight: 2.3, marginLeft: 8}}>
                                        {kpiUnit}
                                    </Typography>
                                </div>

                            </div>
                            <div className={classes.bottomSection}>
                                <span className={classes.previousSection}>
                                    <Typography
                                        component="span"
                                        variant="h6">
                                        {comparisonText}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="h3"
                                        color="secondary"
                                        style={{marginLeft: 8}}>
                                        {comparisonValue}
                                    </Typography>
                                </span>
                                <div className={classes.pctSection}>
                                    {pctValue >= 0
                                        ? <ArrowDropUpIcon color="secondary" fontSize="large"
                                                           className={classes.updownIcon}/>
                                        : <ArrowDropDownIcon color="secondary" fontSize="large"
                                                             className={classes.updownIcon}/>}

                                    <Typography component="span" variant="h3" color="secondary">
                                        {pctValue}{pctValue && '%'}
                                    </Typography>
                                </div>
                            </div>
                        </>)
                }
            </div>
        )
    }
}

export default KpiCard;