import React, { Component } from 'react';
import { observable, reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import { LinearProgress, Typography, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import HeadingBar from '../../components/app/HeadingBar';
import KpiCard from '../../components/analytics/KpiCard';
import TopFiveResource from '../../components/analytics/TopFiveResource';
import { FormattedMessage } from 'react-intl';
import VerticalSeparator from '../../components/ui/VerticalSeparator';
import { sortRows } from '../../stores/utils';
import { formatNumber } from "../../core/utils";
import { RouterState } from "mobx-state-router";

const styles = theme => ({
    outer: {
        marginTop: theme.spacing(6)
    },
    separation: {
        marginRight: theme.spacing(2)
    }
});

@inject('rootStore', 'properties')
@withStyles(styles)
@observer
class KpiSection extends Component {

    @observable topDeviationsEnergy = [];
    @observable topDeviationsPower = [];


    componentDidMount() {
        const { properties } = this.props;

        disposeOnUnmount(this, reaction(
            () => {
                return properties.tableData.length
            },
            () => {
                this.topDeviationsPower = sortRows(properties.tableData, "power_deviation", "desc", true).slice(0, 5);
                this.topDeviationsEnergy = sortRows(properties.tableData, "energy_deviation", "desc", true).slice(0, 5)
            }, {
            fireImmediately: true
        }
        ))

        disposeOnUnmount(this, reaction(
            () => {
                let c = properties.requiredBlocksForKpiCard()
                c.push(properties.all_properties ? properties.all_properties.length : 0)
                return c
            },
            async () => {
                let blockNames = properties.requiredBlocksForKpiCard()
                await properties.getKpiCardData(blockNames)
            }
        ))

        disposeOnUnmount(this, reaction(
            () => {
                return properties.fetchedKpiBlocks
            },
            async () => {
                properties.processKpiCardData()
            }
        ))
    }

    onClickTopFiveResource = (resource_id) => {
        const { rootStore: { routerStore } } = this.props;
        routerStore.goTo(new RouterState("subdetail", { id: resource_id }))
    }

    render() {
        const { properties, classes } = this.props;
        return (
            <div className={classes.outer}>
                <HeadingBar>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center">
                        <Typography variant="h5" color="textPrimary">
                            <FormattedMessage id="all_properties" />
                        </Typography>
                        <div className={classes.separation} />
                        <Typography variant="h6" color="inherit">
                            ({properties.all_properties ? properties.all_properties.length : 'NA'} properties)
                    </Typography>
                    </Box>
                </HeadingBar>
                {properties.is_fetching && <LinearProgress />}
                <Box display="flex" flexDirection="row" mt={1}>
                    <div style={{ width: '33%' }}>
                        <KpiCard
                            title={<FormattedMessage id="energy_consumption_year" values={{ year: properties.current_year }} />}
                            subtitle={<span>(<FormattedMessage id="expected" />)</span>}
                            haveData={!!properties.kpiCardData}
                            kpiValue={formatNumber(properties.kpiCardData.current_year)}
                            kpiUnit="GWh"
                            comparisonText={<span><FormattedMessage id="previous_year" />:</span>}
                            comparisonValue={`${formatNumber(properties.kpiCardData.previous_year)} GWh`}
                            pctValue={formatNumber(properties.kpiCardData.deviation)}
                        />
                    </div>
                    <VerticalSeparator />
                    <div style={{ width: '33%' }}>
                        <TopFiveResource
                            title={<FormattedMessage id="deviation_monthly_forecast" />}
                            subtitle={<FormattedMessage id="energy_unit" values={{ unit: "kWh" }} />}
                            haveData={this.topDeviationsEnergy.length > 0}
                            list={this.topDeviationsEnergy}
                            idAttr='id'
                            addressAttr='address'
                            cityAttr="city"
                            paramAttr="energy"
                            onClickCb={this.onClickTopFiveResource}
                        />
                    </div>
                    <VerticalSeparator />
                    <div style={{ width: '33%' }}>
                        <TopFiveResource
                            title={<FormattedMessage id="deviation_monthly_forecast" />}
                            subtitle={<FormattedMessage id="power_unit" values={{ unit: "kW" }} />}
                            haveData={this.topDeviationsPower.length > 0}
                            list={this.topDeviationsPower}
                            idAttr='id'
                            addressAttr='address'
                            cityAttr="city"
                            paramAttr="power"
                            onClickCb={this.onClickTopFiveResource}
                        />
                    </div>
                </Box>
            </div>
        )
    }
}


export default KpiSection;