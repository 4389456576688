import { action, flow } from 'mobx';
import URLS from '../conf/urls';
import { CODES } from '../conf/errors';
import { formatDateAPI, getNetworksFromPermTree, getSubStationfromNetwork } from './utils';
import { sleep } from '../core/utils';
import { EPO_CHECK_DURATION } from "../conf/conf";

function urlWithParams(nurl, params) {
    let url = new URL(nurl)
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return url;
}

function StrRepr(msg) {
    if (typeof (msg) === "object") {
        return JSON.stringify(msg);
    } else {
        return msg;
    }
}

export const messages = {
    unable_to_fetch_user_network: "Unable To Fetch User Network",
    unable_to_get_substation: "Unable to get Substation",
    not_permitted: "You do not have required permissions for network"
}


export default class ApiProvider {

    getNetworksForUser = flow(function* () {
        let headers = yield this.parent.session.authHeaders();
        try {
            const res = yield fetch(
                urlWithParams(URLS.mdsl + '/users', {}),
                { headers });
            if (res.ok) {
                const resdata = yield res.json();
                let networks = getNetworksFromPermTree(resdata);
                return networks;
            } else {
                console.log("getNetwork for user", res.status)
                throw messages.unable_to_fetch_user_network
            }
        } catch (err) {
            console.log(err);
            //this.processErrors(err, 'getting network for user');
            throw messages.unable_to_fetch_user_network
        }
    });
    // getUserInfo = flow(function* (token) {
    //     let headers = { Authorization: `Bearer ${token}` };
    //     const res = yield fetch(
    //         urlWithParams('https://' + process.env.AUTH0_DOMAIN + '/userinfo', {}, { headers })
    //     )
    //     console.log("auth get user info", res);
    // })
    getSubstationSummaryForNetwork = flow(function* (network) {
        let headers = yield this.parent.session.authHeaders();
        const res = yield fetch(
            urlWithParams(URLS.mdsl + '/resource', {
                resource_type: 'network',
                resource_name: network
            }),
            {
                headers,
            });
        if (res.ok) {
            const resdata = yield res.json();
            let substations = getSubStationfromNetwork(resdata);
            return substations;
        } else {
            if (res.status === 403) {
                throw Error("You do not have permissions for this network")
            } else {
                throw Error("unable to get substations for network")
            }
        }
    })
    checkUserExistsInMDSL = flow(function* () {
        let headers = yield this.parent.session.authHeaders();
        try {
            const res = yield fetch(
                urlWithParams(URLS.mdsl + '/users', {}),
                { headers });
            if (res.ok) {
                return true
            } else {
                return false
            }
        } catch (err) {
            console.log(err);
            if (err.response.status === 404) {
                return false
            }
            throw err
        }
    })
    registerUserInMDSL = flow(function* () {
        let headers = yield this.parent.session.authHeaders();
        try {
            const res = yield fetch(
                urlWithParams(URLS.mdsl + '/users', {}),
                { headers, method: "post" });
            if (res.ok) {
                return true
            } else {
                return false
            }
        } catch (err) {
            console.log(err);
            throw err
        }
    })
    getAllSubstations = flow(function* () {
        //let networks = yield this.getNetworksForUser();
        //networks = networks.indexOf('open_') >= 0 ? ['open_'] : [] // TODO: support more networks
        let networks = ['open_'];
        let all_substations = []
        try {
            let substation_groups = yield Promise.all(networks.map(n => this.getSubstationSummaryForNetwork(n)));
            for (let i = 0; i < substation_groups.length; i++) {
                let network = networks[i];
                let substations = substation_groups[i]
                for (let sub of substations) {
                    all_substations.push([sub, network])
                }
            }
        } catch (err) {
            throw err;
        }
        //console.log("substations for user", all_substations)
        return all_substations
    })
    getInfoBlock = flow(function* ({
        resource_type,
        resource_id,
        filter_by = null,
        block_names,
        format = 'data_frame'
    }) {
        filter_by = filter_by || resource_type;
        let headers = yield this.parent.session.authHeaders()

        const url = urlWithParams(URLS.mdsl + '/info-data', {
            resource_type,
            filter_names: resource_id,
            filter_by,
            block_names,
            format
        })

        try {
            let res = yield fetch(url, { headers })
            if (res.ok) {
                let res_json = yield res.json();
                return res_json.data;
            } else {
                return null
            }
        } catch (err) {
            console.log("getInfoBlock", err)
            return null;
        }
    })
    getWeatherData = flow(function* ({ coordinate, start_date, end_date, metrics = 't', }) {
        /*
        TODO: check lat lon placement is right.
         */
        try {
            let res = yield fetch(urlWithParams(URLS.weather + '/weather-request/', {
                location: `${coordinate.lat},${coordinate.lon}`,
                start_date: formatDateAPI(start_date),
                end_date: formatDateAPI(end_date),
                metrics: metrics,
                page_size: 0
            }), { headers: { 'authorization': 'WDSL wdsl=NEGEM6HKDM8PQENLOE9OUK3B614A7TZX' } })
            console.log("got weather data from mdsl", res.data);
        } catch (err) {
            console.log("getting weather data", err);
            throw Error("unable to get weather data")
        }
    })
    getMeterData = flow(function* ({
        resource_type,
        filter_names,
        components,
        ...extraOptions
    }) {
        /*
        resource_type in 'substation','cluster'
        filter_names = string1,string2
        components = string1,string2
        *filter_by = substation, cluster, network, policy, group.
        *date_min, date_max = yyyy-mm-ddTHH:MM:SS
         *stages=string0,string1,… default='clean'
         */
        try {
            let params = {
                resource_type,
                filter_names,
                components,
            }
            for (let option of ['filter_by', 'date_min', 'date_max', 'stages']) {
                if (extraOptions.hasOwnProperty(option)) {
                    if (option === "date_min" || option === "date_max") {
                        params[option] = formatDateAPI(extraOptions[option])
                    } else {
                        params[option] = extraOptions[option]
                    }
                }
            }
            let headers = yield this.parent.session.authHeaders()
            const res = yield fetch(urlWithParams(URLS.mdsl + '/meter-data', params), { headers })
            if (res.ok) {
                let res_json = yield res.json();
                return res_json.data;
            } else {
                return null
            }
        } catch (err) {
            console.log("unable to get meter data")
            throw Error("unable to get meter data")
        }
    })
    getEPDataAsync = flow(function* ({
        resource_type,
        resource_id,
        mt_start_date,
        mt_end_date,
        cs_start_date,
        cs_end_date,
        weather_source = 'forecast',
        return_predictors = 't',
        meter_type = ['heat_energy', 'supplytemp', 'returntemp', 'volume']
    }) {
        let data = {
            object_type: resource_type,
            ids: [resource_id,],
            date_min_training: formatDateAPI(mt_start_date),
            date_max_training: formatDateAPI(mt_end_date),
            date_min: formatDateAPI(cs_start_date),
            date_max: formatDateAPI(cs_end_date),
            weather_source,
            [`method-type`]: 'get',
            [`method-name`]: 'get-prediction',
            meter_type: meter_type.join(','),
            return_predictors,
            return_data: true
        };
        let headers = yield this.parent.session.authHeaders()
        let request_id = null;
        let submit_job_details = null;
        let job_status_ok = false;
        let job_posted_ok = false;
        let res = null;
        try {
            res = yield fetch(
                urlWithParams(URLS.epo + '/postAPIRequest4Orchestration',
                    { return_request_id: true }),
                {
                    method: "post",
                    headers,
                    body: JSON.stringify(data)
                })
            if (res.ok && (res.status === 202 || res.status === 200)) {
                submit_job_details = yield res.json()
                if (submit_job_details.success) {
                    job_posted_ok = true;
                }
            }
        } catch (err) {
            console.log("postAPIRequest4Orchestration", err)
            throw Error("unable to post request")
        }
        if (!job_posted_ok) {
            throw Error("unable to get epo job result")
        }
        request_id = submit_job_details.request_id[0];
        while (true) {
            yield sleep(EPO_CHECK_DURATION)
            try {
                res = yield fetch(urlWithParams(URLS.epo + '/status', { request_id }),
                    { headers })
                let res_json = yield res.json()
                if (res.ok) {
                    if (['error', 'cancel'].indexOf(res_json.status) >= 0) {
                        break;
                    }
                    if (res_json.status === "done") {
                        job_status_ok = true
                        break;
                    }
                }
            } catch (err) {
                console.log("unable to get job status", err)
                throw Error("unable to get epo job result")
            }
        }
        if (!job_status_ok) {
            throw Error("unable to get epo job result")
        }
        try {
            res = yield fetch(
                urlWithParams(
                    URLS.epo + '/get-result',
                    { request_id },
                ), {
                headers
            })
            if (res.ok) {
                let res_json = yield res.json()
                return res_json.result;
            }
        } catch (err) {
            throw Error("unable to get epo job result")
        }
    })

    constructor(parent) {
        this.parent = parent;
        this.processErrors = this.processErrors.bind(this);
    }

    @action.bound
    processErrors(err, msg) {
        if (err.response) {
            switch (err.response.status) {
                case 400:
                    this.parent.app.gotError(
                        CODES.bad_request,
                        { msg: StrRepr(msg), err: StrRepr(err.response.data.error) })
                    break;
                case 401:
                    this.parent.session.logout();
                    break;
                case 500:
                    this.parent.app.gotError(
                        CODES.server_error,
                        { msg: StrRepr(msg), err: StrRepr(err.response.data.error) })
                    break;
                default:
                    break;
            }
        } else {
            this.parent.app.gotError(
                CODES.unreachable,
                { msg: msg, err: err.message })
        }
    }
}