import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {formatNumber} from '../../core/utils';
import {isVal} from '../../stores/utils';

const styles = theme => ({
    root_dec: {
        color: theme.palette.success.main,
    },
    root_inc: {
        color: theme.palette.error.main
    },
    nodata: {
        color: theme.palette.grey.blue28
    }
});

@withStyles(styles)
class DeviationValue extends Component {
    render() {
        const {row, colname, classes} = this.props;
        const clsDiv = clsx( row[colname] >= 0 ? classes.root_inc : classes.root_dec);
        if (isVal(row[colname])) {
            return (
                <Typography component="span" variant="body2" className={clsDiv}>
                    {formatNumber(row[colname])}%
                </Typography>)
        } else {
            return <Typography component="span" variant="body2" className={classes.nodata}>NoData</Typography>
        }

    }
}

export default DeviationValue;