import React from 'react';
import {observer} from 'mobx-react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import DeviationCell from "./DeviationCell";

const style = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: theme.spacing(4),
        backgroundColor: '#fff',
        border: 'solid 1px #eceff0',
        '&:nth-child(even)': {
            backgroundColor: '#f5f7f8'
        }
    },
    text: {
        //width: '70%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: theme.spacing(2)
    },
    readings: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexGrow: 1,
        color: '#bb4a4a',
        paddingRight: theme.spacing(2)
    },
    address: {
        cursor: "pointer"
    }
});

@withStyles(style)
@observer
class ResourceLine extends React.Component {
    onClick = () => {
        const {reading, idAttr, onClickCb} = this.props;
        onClickCb(reading[idAttr])
    }

    render() {
        const {classes, reading, addressAttr, cityAttr, paramAttr} = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.text}>
                    <Typography
                        variant="body2"
                        color="primary"
                        onClick={this.onClick}
                        className={classes.address}>
                        {reading[addressAttr]}
                    </Typography>
                    <Typography variant="caption" color="inherit" style={{marginLeft: 8}}>
                        {reading[cityAttr]}
                    </Typography>
                </div>
                <div className={classes.readings}>
                    <DeviationCell row={reading} colname={paramAttr}/>
                </div>
            </div>
        );
    }
}

export default ResourceLine;