import {toBlkMonth} from '../core/utils';

const STRING_TYPE = {
    type: 'str',
    physical: 'name',
    unit: null,
};
const ENERGY_TYPE = {
    type: 'num',
    physical: 'heat_energy',
    unit: 'kWh',
}
const TEMP_TYPE = {
    type: 'num',
    physical: 'heat',
    unit: 'tempC',
}
const FLOW_TYPE = {
    type: 'num',
    physical: 'vol',
    unit: 'm^3',
}

const GPS_TYPE = {
    type: 'coord',
    physical: 'location',
    unit: null
}

const DATETIME_TYPE = {
    type:"datetime",
    physical: 'time',
    unit:'s'
}

const TIMESTAMP_TYPE = {
    type:"timestamp",
    physical:'time',
    unit:'s'
}

function generate_block_definition({name, index, rgx, to_block_name, monthFields = [], columns,parse_block_name}) {
    const monthFieldSets = new Set(monthFields)
    let blockDef = {
        index,
        to_block_name,
        columns
    }
    if(parse_block_name){
        blockDef.parse_block_name = parse_block_name
    }else{
        blockDef.parse_block_name = function (blockName) {
            const res = rgx.exec(blockName)
            if (res) {
                //console.log("block def",res);
                let ret = {
                    blk: name,
                }
                for (let matchkey of Object.keys(res.groups)) {
                    if (monthFieldSets.has(matchkey)) {
                        ret[matchkey] = (res.groups[matchkey] - 1).toString()
                    } else {
                        ret[matchkey] = res.groups[matchkey]
                    }
                }
                return ret;
            }
            return null;
        }
    }
    return blockDef;
}

export const CORE_YEARLY_BLOCK = generate_block_definition({
    name: 'core_yearly',
    index: "resource_name",
    rgx: /^(?<blkname>core_(?<year>\d{4}))(\$(?<col>.*)|)$/,
    to_block_name: function ({year}) {
        return `core_${year}`
    },
    columns: {
        resource_name: STRING_TYPE,
        heat_energy_sum: ENERGY_TYPE,
        volume_sum: FLOW_TYPE,
        returntemp_flowweighted_avg: TEMP_TYPE
    }
})

export const CORE_MONTHLY_BLOCK = generate_block_definition({
    name: 'core_monthly',
    index: "resource_name",
    rgx: /^(?<blkname>core_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
    to_block_name: function ({year, month}) {
        return `core_${year}-${toBlkMonth(month)}`
    },
    monthFields: ['month'],
    columns: {
        resource_name: STRING_TYPE,
        heat_energy_sum: ENERGY_TYPE,
        volume_sum: FLOW_TYPE,
        returntemp_flowweighted_avg: TEMP_TYPE
    }
})

export const INSTALL_ADDRESS_BLOCK = generate_block_definition({
    name: "install_address",
    index: "resource_name",
    rgx: /^(?<blkname>(?<blk>install_address))(\$(?<col>.*)|)$/,
    to_block_name: function ({year, month}) {
        return "install_address"
    },
    columns: {
        resource_name: STRING_TYPE,
        street: STRING_TYPE,
        district: STRING_TYPE,
        zip: STRING_TYPE,
        region: STRING_TYPE,
    }
})

export const EPCORE_NORMALIZED_BLOCK = generate_block_definition({
    name: "epcore_normalized",
    index: "resource_name",
    rgx: /^(?<blkname>ep_(?<year>\d{4})-(?<month>\d{2})_core_1000)(\$(?<col>.*)|)$/,
    to_block_name: function ({year, month}) {
        return `ep_${year}-${toBlkMonth(month)}_core_1000`
    },
    monthFields: ['month'],
    columns: {
        resource_name: STRING_TYPE,
        returntemp_flowweighted_avg: TEMP_TYPE,
        heat_energy_sum: ENERGY_TYPE,
        volume_sum: FLOW_TYPE,
    }
})

export const EPCORE_NORMALIZED_MONTHLY_BLOCK = generate_block_definition({
    name: "epcore_normalized_monthly",
    index: "resource_name",
    rgx: /^(?<blkname>ep_(?<syear>\d{4})-(?<smonth>\d{2})_core_1000)-(?<month>\d{2})(\$(?<col>.*)|)$/,
    to_block_name: function ({syear,smonth,month}) {
        return `ep_${syear}-${toBlkMonth(smonth)}_core_1000-${toBlkMonth(month)}`
    },
    monthFields: ['month','smonth'],
    columns: {
        resource_name: STRING_TYPE,
        returntemp_flowweighted_avg: TEMP_TYPE,
        heat_energy_sum: ENERGY_TYPE,
        volume_sum: FLOW_TYPE,
    }
})

export const EPCORE_BLOCK = generate_block_definition({
    name: "epcore",
    index: "resource_name",
    rgx: /^(?<blkname>ep_(?<syear>\d{4})-(?<smonth>\d{2})_core_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
    to_block_name: function ({year, month, syear, smonth}) {
        return `ep_${syear}-${toBlkMonth(smonth)}_core_${year}-${toBlkMonth(month)}`
    },
    monthFields: ['month', 'smonth'],
    columns: {
        resource_name: STRING_TYPE,
        returntemp_flowweighted_avg: TEMP_TYPE,
        heat_energy_sum: ENERGY_TYPE,
        volume_sum: FLOW_TYPE,
    }
})

export const EP_PEAK_POWER_BLOCK = generate_block_definition({
    name: 'ep_peak_power',
    index: "resource_name",
    rgx: /^(?<blkname>ep_(?<syear>\d{4})-(?<smonth>\d{2})_peak_(?<res>day|hour)_(?<hist>1month|12month)_(?<interval>1|123|345)_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
    to_block_name: function ({year, month, res = 'day', hist = '1month', interval = '1', syear, smonth}) {
        return `ep_${syear}-${toBlkMonth(smonth)}_peak_${res}_${hist}_${interval}_${year}-${toBlkMonth(month)}`
    },
    monthFields: ['month', 'smonth'],
    columns: {
        resource_name: STRING_TYPE,
        avg_peak_flow: FLOW_TYPE,
        avg_peak_power: ENERGY_TYPE,
    }
})

export const MEASURED_PEAK_POWER_BLOCK = generate_block_definition({
    name: 'measured_peak_power',
    index: "resource_name",
    rgx: /^(?<blkname>peak_(?<res>day|hour)_(?<hist>1month|12month)_(?<interval>1|123|345)_(?<year>\d{4})-(?<month>\d{2}))(\$(?<col>.*)|)$/,
    to_block_name: function ({year, month, res = 'day', hist = '1month', interval = '1'}) {
        return `peak_${res}_${hist}_${interval}_${year}-${toBlkMonth(month)}`
    },
    monthFields: ['month',],
    columns: {
        resource_name: STRING_TYPE,
        avg_peak_flow: FLOW_TYPE,
        avg_peak_power: ENERGY_TYPE,
    }
})

export const LOCATION_BLOCK = generate_block_definition({
    name: "location",
    index: "resource_name",
    rgx: /^(?<blkname>location)(\$(?<col>.*)|)$/,
    to_block_name: function () {
        return 'location'
    },
    columns: {
        resource_name: STRING_TYPE,
        street_name: STRING_TYPE,
        grid_area: STRING_TYPE,
        street_nr: STRING_TYPE,
        coordinates: GPS_TYPE,
        weather_coordinates: GPS_TYPE
    }
})

export const WEATHER_BLOCK = generate_block_definition({
    name:"weather",
    index:"datetime",
    to_block_name:function(){
        return 'weather'
    },
    parse_block_name:function(blkName){
        if(blkName === "datetime" || blkName === "t"){
            return {blk:'weather',blkname:'weather',col:blkName}
        }else{
            return null
        }
    },
    columns:{
        datetime:DATETIME_TYPE,
        t:TEMP_TYPE
    }
})

const METER_DATA_COLS = new Set([
    'timestamp',
    'heat_energy',
    'volume',
    'supplytemp',
    'returntemp'])

export const METER_DATA_BLOCK = generate_block_definition({
    name:"meter_data",
    index:"timestamp",
    to_block_name:function(){
        return 'meter_data'
    },
    parse_block_name:function(blkName){
        if(METER_DATA_COLS.has(blkName)){
            return {blk:'meter_data',blkname:'meter_data',col:blkName}
        }else{
            return null;
        }
    },
    columns:{
        timestamp:TIMESTAMP_TYPE,
        heat_energy:ENERGY_TYPE,
        volume:FLOW_TYPE,
        supplytemp:TEMP_TYPE,
        returntemp:TEMP_TYPE
    }
})
// this cannot be used in addition to other blocks, only infoblocks can be combined
export const EP_SIMULATION_BLOCK = generate_block_definition({
    name:"ep_simulation",
    index:"timestamp",
    to_block_name:function(){
        return 'ep_simulation'
    },
    parse_block_name:function(blkName){
        return {blk:'ep_simulation',blkname:'ep_simulation',col:blkName}
    },
    columns:{
        timestamp:TIMESTAMP_TYPE,
        heat_energy:ENERGY_TYPE,
        volume:FLOW_TYPE,
        supplytemp:TEMP_TYPE,
        returntemp:TEMP_TYPE,
        t:TEMP_TYPE
    }
})