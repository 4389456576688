/* Copyright (C) 2019 Utilifeed AB. All Rights Reserved. */

import React from 'react';
import {computed} from 'mobx';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import {withTheme} from '@material-ui/core/styles';
import highchartsMore from 'highcharts/highcharts-more';
import {formatNumber} from '../../core/utils';

require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
highchartsMore(Highcharts);

@withTheme
class Graph extends React.Component {

    @computed get chartOptions() {
        const {
            //title,
            chartData,
            type,
            xAxisTitle,
            yAxisTitle,
            step,
            xAxisAppend,
            yAxisAppend,
            isLog = false,
            widthBar,
            average,
            isAverage,
            xAxisType,
            xAxis,
            theme,
            yAxis,
            hoverShow = false,
            boost = false,
            seriesOptions = {},
            chartOptions = {},
            ...extraOptions
        } = this.props;
        return {
            chart: {

                type: type || '',
                zoomType: 'x',
                style: {
                    overflow: 'visible'
                },
                boost: {
                    seriesThreshold: 10,
                },
                ...chartOptions
            },
            credits: {
                enabled: false
            },
            title: {
                text: '',
            },
            subtitle: {
                text: isAverage ? `Average : ${average}` : '',
                x: 120,
                y: 12,
                style: {
                    color: theme.palette.primary.main,
                    fontFamily: theme.typography.caption.fontFamily,
                    fontSize: theme.typography.caption.fontSize,
                    position: 'absolute',
                    zIndex: 2,
                    right: '125px',
                    top: 8,
                }
            },
            yAxis: yAxis.length>0 ? yAxis : [{
                title: {
                    text: yAxisTitle || '',
                    style: {
                        fontSize: theme.typography.caption.fontSize,
                        fontFamily: theme.typography.caption.fontFamily,
                        fontWeight: theme.typography.subtitle2.fontWeight,
                    }
                },
                labels: {
                    formatter: function () {
                        return yAxisAppend ? formatNumber(this.value) + yAxisAppend : formatNumber(this.value);
                    }
                }
            }],
            xAxis: xAxis ? xAxis : {
                type: xAxisType || 'linear',
                title: {
                    text: xAxisTitle || '',
                    style: {
                        fontSize: theme.typography.caption.fontSize,
                        fontFamily: theme.typography.caption.fontFamily,
                        fontWeight: theme.typography.subtitle2.fontWeight,
                    }
                },
                labels: {
                    formatter: function () {
                        let xValue = this.value;
                        if (isLog) {
                            xValue = Math.pow(Math.E, this.value);
                        }
                        xValue = formatNumber(xValue);
                        return xAxisAppend ? xValue + xAxisAppend : xValue
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle'
            },
            navigation: {
                menuStyle: {
                    zIndex: 6,
                    overflow: "visible",
                },
            },
            plotOptions: {
                series: {
                    stickyTracking: false,
                    events: {},
                    label: {
                        connectorAllowed: false
                    },
                    color: theme.palette.secondary.light,
                    pointStart: 1,
                    animation: false,
                    step: (step && 'right') || '',
                    turboThreshold: boost ? 1 : 0,
                    pointWidth: widthBar ? widthBar : undefined,
                    cursor: 'pointer',
                    states: hoverShow ? {
                        hover: {
                            halo: {
                                attributes: {
                                    'stroke-width': 6,
                                    stroke: theme.palette.secondary.light,
                                },
                                size: 7
                            },
                        }
                    } : {},
                    marker: {
                        states: {
                            select: {
                                radius: 7,
                                lineWidth: 4,
                                lineColor: theme.palette.secondary.dark,
                                fillColor: theme.palette.secondary.main,
                            }
                        }
                    },
                    ...seriesOptions
                },
                area: {
                    fillOpacity: 0.5
                },
            },
            series: chartData,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            },
            ...extraOptions
        };
    }

    render() {
        const {chartData, chartCompOptions = {}} = this.props;
        return chartData
            ? (
                <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    immutable={false}
                    options={this.chartOptions}
                    {...chartCompOptions} />
            ) : null;

    }
}

export default Graph;
