import React from 'react';
import {inject, observer} from "mobx-react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import UtfCheckbox from "../../components/ui/UtfCheckbox";
import {OPTION_META} from "./Constants";
import {FormattedMessage} from "react-intl";

@inject('sub')
@observer
class CheckOption extends React.Component {
    handleChange = (e) => {
        const {lbl, sub} = this.props;
        sub.selectGraphSeries(lbl.option, e.target.checked)
    }

    render() {
        const {sub, lbl} = this.props;
        return (<FormControlLabel
            control={<UtfCheckbox
                color={OPTION_META[lbl.option].color}
                size="medium"
                checked={sub.graph_metric_option[lbl.option]}
                onChange={this.handleChange}
                name={lbl.label}/>}
            label={<FormattedMessage id={lbl.option} />}
        />);
    }
}

export default CheckOption;