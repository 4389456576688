import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Logout(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M7.5,10.18a.75.75,0,0,1,.75-.75H15V5a.37.37,0,0,1,.64-.27l6.64,6.71a.75.75,0,0,1,0,1.06l-6.64,6.71a.37.37,0,0,1-.53,0A.39.39,0,0,1,15,19V14.6H8.25a.76.76,0,0,1-.75-.75V10.18m-1.5,0v3.64a2.25,2.25,0,0,0,2.25,2.25H13.5V19a1.87,1.87,0,0,0,3.2,1.33l6.64-6.7a2.25,2.25,0,0,0,0-3.18L16.7,3.7A1.88,1.88,0,0,0,13.5,5V7.9H8.25A2.25,2.25,0,0,0,6,10.18ZM0,5.25v13.5A2.25,2.25,0,0,0,2.25,21H8.44A.56.56,0,0,0,9,20.44v-.38a.56.56,0,0,0-.56-.56H2.25a.76.76,0,0,1-.75-.75V5.25a.76.76,0,0,1,.75-.75H8.44A.56.56,0,0,0,9,3.94V3.56A.56.56,0,0,0,8.44,3H2.25A2.25,2.25,0,0,0,0,5.25Z"/>
        </SvgIcon>
    )
}
