import React, {Component} from 'react';
import {observer} from 'mobx-react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from './UtfTableCell';
import TableRow from '@material-ui/core/TableRow';


@observer
class UtfTableHead extends Component {

    render() {
        const {columns, store} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {
                        columns.map(col => <TableCell key={col.id} current={col} store={store}/>)
                    }
                </TableRow>
            </TableHead>
        );
    }
}

export default UtfTableHead;