import { action, flow, observable } from 'mobx';
import addMonths from 'date-fns/addMonths';
import { BLOCK, DUR_LATEST } from '../conf/constants';
import { deviation_pct, getActiveYM, isVal } from './utils';
import addYears from 'date-fns/addYears';
import { range } from "../core/utils";
import { autoSave } from './cache';

class Properties {
    @observable is_fetching = false;
    @observable all_properties = null;

    @observable duration = DUR_LATEST;
    @observable compare_with = 'expected';
    @observable current_year = '2017'
    @observable current_month = '11'

    @observable tableData = [];
    @observable kpiCardData = {};
    @observable fetchedBlocks = null;
    @observable fetchedKpiBlocks = null;
    @observable sortId = "id";
    @observable sortDirection = "asc";
    @observable rowsPerPage = 10;
    @observable pageNo = 0;
    @observable err_msg = "";

    getTableData = flow(function* (blockNames) {
        this.tableData = [];
        this.is_fetching = true;
        try {
            let blocks = yield this.parent.ibs.getInfoBlocks({
                resource_type: 'substation',
                resource_id: 'open_',
                filter_by: 'network',
                block_names: blockNames
            })
            this.fetchedBlocks = blocks;
        } catch (err) {
            console.log("fetched blocks", err);
        } finally {
            this.is_fetching = false;
            console.log("set is fetching false get table data")
        }
    })

    getKpiCardData = flow(function* (blockNames) {
        this.kpiCardData = {};
        this.is_fetching = true;
        try {
            let blocks = yield this.parent.ibs.getInfoBlocks({
                resource_type: 'substation',
                resource_id: 'open_',
                filter_by: 'network',
                block_names: blockNames
            })
            this.fetchedKpiBlocks = blocks;
        } catch (err) {
            console.log("fetched blocks", err);
        } finally {
            this.is_fetching = false;
            console.log("set is fetching false getKPI card")
        }
    })

    @action.bound
    processKpiCardData() {

        const [year, month] = getActiveYM()
        const syear = addYears(new Date(year, month + 1, 15), -1).getFullYear()
        const pyear = addYears(new Date(year, month, 15), -1).getFullYear()
        const smonth = '11';
        const calMonthLeft = range(month + 1, 12, 1)
        const calMonthPassed = range(0, Math.min(month + 1, 12), 1)
        let thisYearBlockNames = []
        for (let i = 0; i < calMonthPassed.length; i++) {
            thisYearBlockNames.push(BLOCK.core_monthly.to_block_name({ year, month: calMonthPassed[i] }))
        }
        for (let i = 0; i < calMonthLeft.length; i++) {
            thisYearBlockNames.push(BLOCK.epcore_normalized_monthly.to_block_name({ year, month: calMonthLeft[i], syear, smonth }))
        }
        const lastYearBlock = BLOCK.core_yearly.to_block_name({ year: pyear })
        let colSpec = []
        for (let i = 0; i < thisYearBlockNames.length; i++) {
            colSpec.push([
                `${thisYearBlockNames[i]}$heat_energy_sum`, thisYearBlockNames[i]
            ])
        }
        colSpec.push([`${lastYearBlock}$heat_energy_sum`, 'prev_year_sub'])
        const colMapReader = this.parent.ibs.getBlockReaderJson(this.fetchedKpiBlocks, colSpec)
        let aggregates = this.parent.ibs.getJsonReaderAggregator(colMapReader, this.all_properties.map(i => i[0]), [
            [function (agg, row, dest) {
                for (let i = 0; i < 12; i++) {
                    if (isVal(row[thisYearBlockNames[i]])) {
                        agg[dest] += row[thisYearBlockNames[i]]
                    }
                }
            }, 'current_year'],
            [function (agg, row, dest) {
                if (isVal(row.prev_year_sub)) {
                    agg[dest] += row.prev_year_sub
                }
            }, 'previous_year']
        ])
        aggregates['deviation'] = deviation_pct(aggregates, 'current_year', 'previous_year')
        if (aggregates.previous_year) {
            aggregates.previous_year = aggregates.previous_year / 1000000
        }
        if (aggregates.current_year) {
            aggregates.current_year = aggregates.current_year / 1000000
        }
        this.kpiCardData = aggregates;
    }

    onLogin = flow(function* () {
        try {
            var userExistsInMdsl = yield this.parent.api.checkUserExistsInMDSL();
            if (!userExistsInMdsl) {
                yield this.parent.api.registerUserInMDSL();
            }
        } catch (err) {
            this.err_msg = "unable to verify user on mdsl"
        }
        try {
            this.all_properties = yield this.parent.api.getAllSubstations()
        } catch (err) {
            this.err_msg = err.message || "unable to get substations"
        }
    })

    constructor(parent) {
        this.parent = parent;

        this.getColumnSpecs = this.getColumnSpecs.bind(this);
        this.requiredBlocksForComparisonTable = this.requiredBlocksForComparisonTable.bind(this);

        this.shouldStore = this.shouldStore.bind(this);
        this.saveToCache = this.saveToCache.bind(this);
        this.clearCache = this.clearCache.bind(this);

        this.loadFromCache();
        autoSave(this)
    }

    shouldStore() {
        return this.all_properties ? this.all_properties.length : 0;
    }
    saveToCache() {
        localStorage.setItem("all_properties", JSON.stringify(this.all_properties))
    }
    clearCache() {
        localStorage.removeItem("all_properties")
    }

    @action.bound
    loadFromCache() {
        let allp = localStorage.getItem("all_properties");
        if (allp !== null) {
            this.all_properties = JSON.parse(allp);
        }
    }


    @action.bound
    changeRowsPerPage(event) {
        this.rowsPerPage = parseInt(event.target.value, 10);
        this.pageNo = 0
    }

    @action.bound
    changePageNo(e, value) {
        this.pageNo = value
    }

    @action.bound
    changeSortDirection(colId, direction) {
        this.sortId = colId;
        this.sortDirection = direction;
    }

    @action.bound
    sortTableData() {
        const tableDataReader = this.parent.ibs.getBlockReaderJson(this.fetchedBlocks, this.getColumnSpecs())
        const tableData = this.all_properties.map(([sub, network]) => tableDataReader(sub))
        this.tableData = tableData.sort((a, b) => {
            let x = a[this.sortId], y = b[this.sortId];
            if (x === y) {
                return 0
            } else if (!isVal(x)) {
                return this.sortDirection === "asc" ? -1 : 1;
            } else if (!isVal(y)) {
                return this.sortDirection === "asc" ? 1 : -1;
            }
            if (this.sortDirection === "asc") {
                return x < y ? -1 : 1;
            } else {
                return x < y ? 1 : -1;
            }
        })
    }

    requiredBlocksForComparisonTable() {
        const [year, month] = getActiveYM()
        const sdate = addMonths(new Date(year, month, 15), -1)
        const blockParams = {
            year: this.current_year,
            month: this.current_month,
            interval: "1",
            syear: sdate.getFullYear(),
            smonth: sdate.getMonth()
        }
        return ['core_monthly', 'install_address', 'epcore', 'epcore_normalized',
            'ep_peak_power', 'measured_peak_power'].map(blkName => {
                return BLOCK[blkName].to_block_name(blockParams)
            });
    }

    requiredBlocksForKpiCard(date) {
        date = date || new Date(2018, 0, 8)
        const [year, month] = getActiveYM(date)
        const syear = addYears(new Date(year, month + 1, 15), -1).getFullYear()
        const pyear = addYears(new Date(year, month, 15), -1).getFullYear()
        const smonth = '11';
        const calMonthLeft = range(month + 1, 12, 1)
        const calMonthPassed = range(0, Math.min(month + 1, 12), 1)
        let blocks = []
        for (let i = 0; i < calMonthPassed.length; i++) {
            blocks.push(BLOCK.core_monthly.to_block_name({ year, month: calMonthPassed[i] }))
        }
        for (let i = 0; i < calMonthLeft.length; i++) {
            blocks.push(BLOCK.epcore_normalized_monthly.to_block_name({ year, month: calMonthLeft[i], syear, smonth }))
        }
        blocks.push(BLOCK.core_yearly.to_block_name({ year: pyear }))
        return blocks;
    }

    getColumnSpecs() {
        const [year, month] = getActiveYM()
        const sdate = addMonths(new Date(year, month, 15), -1)
        const blockParams = {
            year: this.current_year,
            month: this.current_month,
            interval: "1",
            syear: sdate.getFullYear(),
            smonth: sdate.getMonth()
        }
        const iadd_blk = BLOCK.install_address.to_block_name(blockParams)
        const ep_n = BLOCK.epcore_normalized.to_block_name(blockParams)
        const core = BLOCK.core_monthly.to_block_name(blockParams)
        const ep = BLOCK.epcore.to_block_name(blockParams)
        const ep_peak = BLOCK.ep_peak_power.to_block_name(blockParams)
        const m_peak = BLOCK.measured_peak_power.to_block_name(blockParams)

        return [
            [`${iadd_blk}$resource_name`, 'id'],
            [`${iadd_blk}$street`, 'address'],
            [`${iadd_blk}$region`, 'city'],
            [`${ep_n}$heat_energy_sum`, 'normal_year'],
            [`${core}$heat_energy_sum`, 'energy'],
            [`${ep}$heat_energy_sum`, 'ep_energy'],
            [`${core}$volume_sum`, 'flow'],
            [`${ep}$volume_sum`, 'ep_flow'],
            [`${core}$returntemp_flowweighted_avg`, 'return_temp'],
            [`${ep}$returntemp_flowweighted_avg`, 'ep_return_temp'],
            [function (row) {
                return deviation_pct(row, 'energy', 'ep_energy')
            }, 'energy_deviation'],
            [function (row) {
                return deviation_pct(row, 'return_temp', 'ep_return_temp')
            }, 'return_temp_deviation'],
            [function (row) {
                return deviation_pct(row, 'flow', 'ep_flow')
            }, 'flow_deviation'],
            [`${ep_peak}$avg_peak_power`, 'power'],
            [`${m_peak}$avg_peak_power`, 'm_power'],
            [function (row) {
                return deviation_pct(row, 'm_power', 'power')
            }, 'power_deviation']
        ]
    }

    @action.bound
    changeDuration(value) {
        this.duration = value;
    }

    @action.bound
    changeCompareWith(value) {
        this.compare_with = value;
    }

    @action.bound
    clearMsg() {
        this.err_msg = null;
    }
}

export default Properties;