import {action, flow, observable, ObservableSet, toJS, when} from 'mobx';
import {splitDf} from './utils';
import {EP_SIMULATION_BLOCK, METER_DATA_BLOCK} from "../conf/blocks";
import { autoSave} from './cache';


export function blockID({resource_type, resource_id, filter_by, block_name}) {
    return `${resource_type}$${resource_id}$${filter_by}$${block_name}`;
}

class InfoBlockStore {
    @observable is_fetching = false;
    @observable request_lock_infoblock = [];
    @observable request_lock_meter_data = [];
    @observable request_lock_epo = [];
    @observable available_blocks = new ObservableSet()
    getRequestLock = flow(function* (lockattr) {
        let uuid = `${Math.random(0, 100)}${Math.random(0, 100)}`;
        this[`request_lock_${lockattr}`].push(uuid)
        yield when(() => {
            return this[`request_lock_${lockattr}`].length > 0 && this[`request_lock_${lockattr}`][0] === uuid
        })
        return uuid
    })
    getInfoBlocks = flow(function* ({resource_type, resource_id, filter_by, block_names}) {
        filter_by = filter_by || resource_type;
        const request_lock = yield this.getRequestLock("infoblock")
        try {
            let pending_blocks = this.getPendingBlocks({resource_type, resource_id, filter_by, block_names});
            if (pending_blocks.length > 0) {
                let data = yield this.parent.api.getInfoBlock({resource_type, resource_id, filter_by, block_names});
                let blocks = splitDf(data);
                this.updateCache({resource_type, resource_id, filter_by, blocks})
            }
            let res = this.getInfoBlocksFromCache({resource_type, resource_id, filter_by, block_names})
            return res;
        } catch (err) {
            console.log(err);
        } finally {
            this.releaseRequestLock("infoblock", request_lock)
        }
    })
    getMeterData = flow(function* ({resource_type, resource_id, components, ...extraOptions}) {
        /*
        interface for getting meter data from mdsl.
        for overlayering weather data we need to first get location and get its outdoor temperature series
        and merge meter reading and weather readings into one series before passing it up to the
        consuming function.
         */
        const request_lock = yield this.getRequestLock("meter_data")
        const filter_by="";
        const block_names=["meter_data",]; // TODO: caching from meter data must be removed/updated
                                           // when we enable dynamic parameters
        let pending_blocks = this.getPendingBlocks({
            resource_type,
            resource_id,
            filter_by,
            block_names});
        try {
            if( pending_blocks.length > 0){
                let requestParams = {resource_type, filter_by: resource_type, filter_names: [resource_id], components};
                for (let option of ['filter_by', 'date_min', 'date_max', 'stages']) {
                    if (extraOptions.hasOwnProperty(option)) {
                        requestParams[option] = extraOptions[option]
                    }
                }
                const rawdata = yield this.parent.api.getMeterData(requestParams);
                const splitBlocks = splitDf(rawdata[0], {meter_data: METER_DATA_BLOCK})
                this.updateCache({resource_type, resource_id, filter_by, blocks:splitBlocks})
            }

            return this.getInfoBlocksFromCache({resource_type, resource_id, filter_by, block_names})
        } catch (err) {
            console.log("got error while fetching meter data", err)
        } finally {
            this.releaseRequestLock("meter_data", request_lock)
        }
    })
    getEPOData = flow(function* ({
                                     resource_type,
                                     resource_id,
                                     mt_start_date,
                                     mt_end_date,
                                     cs_start_date,
                                     cs_end_date,
                                     meter_type,
                                     weather_source,
                                     return_predictors,
                                 }) {
        /*
        interface for getting meter data from mdsl.
        for overlayering weather data we need to first get location and get its outdoor temperature series
        and merge meter reading and weather readings into one series before passing it up to the
        consuming function.
         */
        const request_lock = yield this.getRequestLock("epo")
        const block_names=["ep_simulation",]; // TODO: caching from epo data must be removed/updated
        const filter_by = "";                 // when we enable dynamic parameters
        let pending_blocks = this.getPendingBlocks({
            resource_type,
            resource_id,
            filter_by,
            block_names});
        try {
            if(pending_blocks.length>0){
                let requestParams = {
                    resource_type,
                    resource_id,
                    mt_start_date,
                    mt_end_date,
                    cs_start_date,
                    cs_end_date,
                };
                if (meter_type !== undefined) {
                    requestParams.meter_type = meter_type
                }
                if (weather_source !== undefined) {
                    requestParams.weather_source = weather_source
                }
                if (return_predictors !== undefined) {
                    requestParams.return_predictors = return_predictors
                }
                const rawdata = yield this.parent.api.getEPDataAsync(requestParams);
                const blocks = splitDf(rawdata, {ep_simulation: EP_SIMULATION_BLOCK})
                this.updateCache({resource_type, resource_id, filter_by, blocks})
            }
            return this.getInfoBlocksFromCache({resource_type, resource_id, filter_by, block_names})
        } catch (err) {
            console.log("got error while fetching meter data", err)
        } finally {
            this.releaseRequestLock("epo", request_lock)
        }
    })

    constructor(parent) {
        this.parent = parent;
        this.blocks = new Map();
        this.getPendingBlocks = this.getPendingBlocks.bind(this);
        this.updateCache = this.updateCache.bind(this);
        this.getInfoBlocksFromCache = this.getInfoBlocksFromCache.bind(this);

        this.shouldStore = this.shouldStore.bind(this);
        this.saveToCache = this.saveToCache.bind(this);
        this.clearCache = this.clearCache.bind(this);
        this.loadFromCache = this.loadFromCache.bind(this);

        this.loadFromCache()
        autoSave(this)
    }

    shouldStore() {
        return this.available_blocks.size
    }

    @action.bound
    loadFromCache() {
        let blknames = localStorage.getItem("blocks")
        if (blknames !== null) {
            blknames = blknames.split(',')
        } else {
            return
        }
        let loadedBlknames = []
        for (let blkid of blknames) {
            let blkj = localStorage.getItem(blkid)
            if (blkj !== null) {
                blkj = JSON.parse(blkj);
                let idxColId = blkj.columns[blkj.idx].idx;
                blkj.idxMap = new Map(blkj.data.map((row, idx) => {
                    return [row[idxColId], idx]
                }))
                this.blocks.set(blkid, blkj)
                loadedBlknames.push(blkid)
            }
        }
        this.available_blocks = new Set(loadedBlknames)
    }

    saveToCache() {
        try{
           for (let blkName of this.available_blocks) {
            localStorage.setItem(blkName, JSON.stringify(toJS(this.blocks.get(blkName))))
        }
        localStorage.setItem("blocks", Array.from(this.available_blocks.entries()).join(','))
        }catch(err){
            console.log("space full unable to store", err)
        }

    }

    clearCache() {
        for (let blkName of this.available_blocks) {
            localStorage.removeItem(blkName)
        }
        localStorage.removeItem("blocks")
    }

    @action.bound
    releaseRequestLock(lockattr, lockId) {
        if (this[`request_lock_${lockattr}`][0] === lockId) {
            this[`request_lock_${lockattr}`].shift()
        }
    }

    getPendingBlocks({resource_type, resource_id, filter_by, block_names}) {
        let pending_blocks = []
        let tmpBlkId = null;
        for (let block_name of block_names) {
            tmpBlkId = blockID({resource_type, resource_id, filter_by, block_name});
            if (!this.available_blocks.has(tmpBlkId)) {
                pending_blocks.push(block_name)
            }
        }
        return pending_blocks;
    }

    updateCache({resource_type, resource_id, filter_by, blocks}) {
        for (let [block_name, block] of blocks) {
            let tmpBlkId = blockID({resource_type, resource_id, filter_by, block_name})
            this.blocks.set(tmpBlkId, block);
            this.available_blocks.add(tmpBlkId)
        }
    }

    getInfoBlocksFromCache({resource_type, resource_id, filter_by, block_names}) {
        let blocks = {};
        for (let block_name of block_names) {
            let tmpBlkId = blockID({resource_type, resource_id, filter_by, block_name})
            if (this.available_blocks.has(tmpBlkId)) {
                blocks[block_name] = this.blocks.get(tmpBlkId)
            } else {
                throw Error(`block not available ${block_name}`)
            }
        }
        return blocks;
    }

    getBlockReader(blocks, colspecs) {
        /*
        index of first column in spec will be considered.
        primary block would be first column.
        returns a reader function that get values for index as array
        */
        let parseSpec = [];
        for (let i = 0; i < colspecs.length; i++) {
            const [spec, dest] = colspecs[i];
            if (typeof (spec) === "function") {
                parseSpec.push(["function", spec, dest])
            } else if (typeof (spec) === "string") {
                let [blkName, colName] = spec.split('$')
                let blk = blocks[blkName]
                let colspec = blk.columns[colName]
                parseSpec.push(["spec", {blk: blk, col: colspec}, dest])
            } else {
                return false
            }
        }
        return function reader(indexValue) {
            let row = [];
            for (let [specType, spec,] of parseSpec) {
                let val = null;
                if (specType === "spec") {
                    let rowId = spec.blk.idxMap.get(indexValue)
                    if (rowId >= 0) {
                        val = spec.blk.data[rowId][spec.col.idx]
                    }
                } else if (specType === "function") {
                    val = spec(row)
                }
                row.push(val);
            }
            return row
        }
    }

    getBlockReaderJson(blocks, colspecs) {
        /*
        Index of first column in spec will be considered.
        primary block would be first column.
        returns a reader function that get values for index as json object
        */
        let parseSpec = [];
        for (let i = 0; i < colspecs.length; i++) {
            const [spec, dest] = colspecs[i];
            if (typeof (spec) === "function") {
                parseSpec.push(["function", spec, dest])
            } else if (typeof (spec) === "string") {
                let [blkName, colName] = spec.split('$')
                let blk = blocks[blkName]
                let colspec = blk.columns[colName]
                parseSpec.push(["spec", {blk: blk, col: colspec}, dest])
            } else {
                return false
            }
        }
        return function reader(indexValue) {
            let row = {};
            for (let [specType, spec, specDest] of parseSpec) {
                let val = null
                if (specType === "spec") {
                    let rowId = spec.blk.idxMap.get(indexValue)
                    if (rowId >= 0) {
                        val = spec.blk.data[rowId][spec.col.idx]
                    }
                } else if (specType === "function") {
                    val = spec(row)
                }
                row[specDest] = val;
            }
            return row
        }
    }

    getJsonReaderAggregator(jsonReader, indexes, spec) {
        /*
        json Reader for all blocks,
        indexes to do aggregation over.
        spec: [
            [reducingFunction,dest]
        ]
        reducingFunction: currentAggregate, row
         */
        let row;
        let aggregates = {}
        for (let cspec of spec) {
            aggregates[cspec[1]] = 0
        }
        for (let idx of indexes) {
            row = jsonReader(idx)
            for (let cspec of spec) {
                cspec[0](aggregates, row, cspec[1])
            }
        }
        return aggregates;
    }

}

export default InfoBlockStore;