import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LogoText from '../../icons/LogoText';
import {withStyles} from '@material-ui/core/styles';
import LogoutIcon from '../../icons/Logout';
import GlobeIcon from '../../icons/Globe';
import {FormattedMessage} from "react-intl";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    appbar: {
        backgroundColor: theme.palette.primary.dark,
    },
    logo: {
        justifyContent: 'flex-start'
    },
    spacer: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1
    },
    root: {
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(4)
    },
});

@inject('ui', 'session', 'rootStore','locale')
@withStyles(styles)
@observer
class TopNav extends Component {
    goToHome = () => {
        const {rootStore: {routerStore}} = this.props;
        routerStore.goTo("dash");
    }
    logout = () => {
        const {session} = this.props;
        session.logout()
    }

    render() {
        const {classes, locale} = this.props;

        return (
            <AppBar
                elevation={0}
                position="fixed"
                className={classes.appbar}
            >
                <Toolbar
                    classes={{root: classes.root}}
                >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        className={classes.logo}
                        onClick={this.goToHome}
                    >
                        <LogoText/>
                    </IconButton>
                    <Button
                        color="inherit"
                        startIcon={<GlobeIcon/>}
                        onClick={locale.switch}
                    >
                        <Typography variant="button">
                            <FormattedMessage id="switch_lang" />
                        </Typography>
                    </Button>
                    <div className={classes.spacer}/>
                    <IconButton color="inherit" onClick={this.logout}>
                        <LogoutIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    }
}

export default TopNav;