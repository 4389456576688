import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Globe(props) {
    return (
        <SvgIcon {...props}>
            <path
                d="M 12,0 A 12,12 0 1 0 24,12 12,12 0 0 0 12,0 Z m 9.348,7.355 h -3.992 a 14.081,14.081 0 0 0 -2.09,-5.279 10.5,10.5 0 0 1 6.082,5.279 z M 16.258,12 a 22.87,22.87 0 0 1 -0.208,3.1 h -8.1 a 23.15,23.15 0 0 1 0,-6.194 h 8.1 A 22.87,22.87 0 0 1 16.258,12 Z M 12,1.548 c 1.3,0 2.971,2.134 3.779,5.806 H 8.221 C 9.029,3.682 10.7,1.548 12,1.548 Z M 8.734,2.075 A 14.1,14.1 0 0 0 6.644,7.354 H 2.652 A 10.5,10.5 0 0 1 8.734,2.076 Z M 1.548,12 A 10.386,10.386 0 0 1 2.017,8.9 H 6.4 A 24.538,24.538 0 0 0 6.2,12 23.667,23.667 0 0 0 6.4,15.1 H 2.018 A 10.386,10.386 0 0 1 1.548,12 Z m 1.1,4.645 h 3.996 a 14.081,14.081 0 0 0 2.09,5.279 10.5,10.5 0 0 1 -6.082,-5.279 z M 12,22.452 c -1.3,0 -2.971,-2.134 -3.779,-5.806 h 7.558 C 14.971,20.313 13.3,22.452 12,22.452 Z m 3.266,-0.527 a 14.1,14.1 0 0 0 2.09,-5.279 h 3.992 a 10.5,10.5 0 0 1 -6.082,5.278 z M 17.6,15.097 a 24.538,24.538 0 0 0 0.2,-3.1 23.667,23.667 0 0 0 -0.2,-3.1 h 4.379 a 10.451,10.451 0 0 1 0,6.194 z"/>
        </SvgIcon>
    )
}