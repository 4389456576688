/*


refer https://utilifeed.atlassian.net/wiki/spaces/DE/pages/876380379/Meta-data+name+standards
for further details on meta data names;

initial author: Arun Tigeraniya<tigeraniya@gmail.com>

*/
import {
    CORE_MONTHLY_BLOCK,
    CORE_YEARLY_BLOCK,
    EP_PEAK_POWER_BLOCK,
    EPCORE_BLOCK,
    EPCORE_NORMALIZED_BLOCK, EPCORE_NORMALIZED_MONTHLY_BLOCK,
    INSTALL_ADDRESS_BLOCK,
    LOCATION_BLOCK,
    MEASURED_PEAK_POWER_BLOCK
} from "./blocks";
/*
 value types supported in the frontend
*/
export const COLTYPE = {
    number: 'num',
    str: 'str',
    coord: 'coord',
    datetime_s: 'datetime',
    ts_s: 'timestamp',
    date: 'date'
}
export const COL_PARSERS = {
    [COLTYPE.str]: (k) => k,
    [COLTYPE.number]: function (k) {
        let v = parseFloat(k);
        if (Number.isNaN(v)) {
            return null;
        } else {
            return v
        }
    },
    [COLTYPE.coord]: function (d) {
        if (d) {
            let [lat, lon] = d.split(',')
            return {lon: parseFloat(lon), lat: parseFloat(lat)}
        } else {
            return null;
        }
    },
    [COLTYPE.datetime_s]: function (d) {
        return new Date(d) * 1
    },
    [COLTYPE.ts_s]: function (d) {
        return d * 1000
    },
    [COLTYPE.date]: function (d) {
        return new Date(d)
    }
}

export const COL = {
    resource_name: 'resource_name',
    time: 'timestamp',
    date_min: 'date_min',
    date_max: 'date_max',
    customer_name: 'customer',
    zip: 'zip',
    region: 'region',
    street: 'street',
    district: 'district',
    grid_area: 'grid_area',
    postal_zip_code: 'zip',
    street_name: 'street_name',
    street_number: 'street_number',
    street_nr: 'street_nr',
    energy_total: 'heat_energy_sum',
    volume_total: 'volume_sum',
    supply_temp_ft: 'supply_temp_ft',
    supply_temp_pt: 'supply_temp_pt',
    supply_temp_ut: 'supply_temp_ut',
    ret_temp_ft: 'ret_temp_ft',
    ret_temp_pt: 'ret_temp_pt',
    ret_temp_ut: 'ret_temp_ut',
    dlt_temp_ft: 'dlt_temp_ft',
    dlt_temp_pt: 'dlt_temp_pt',
    dlt_temp_ut: 'dlt_temp_ut',
    wn_value: 'wn_value',
    pricing_meta: 'wn_value',
    flow_lmt: 'flow_limit',
    flow_lmtr_t: 'flow_limiter_total',
    energy: 'heat_energy',
    vol: 'volume',
    supply_temp: 'supplytemp',
    ret_temp: 'returntemp',
    coords: 'coordinates',
    w_coords: 'weather_coordinates',
    design_vol_1h: 'volume_design_1h',
    design_vol_24h: 'volume_design_24h',
    design_vol_90_1h: 'volume_design_90_1h',

    design_vol_1h_date: 'volume_design_1h_date',
    design_vol_24h_date: 'volume_design_24h_date',
    design_vol_90_1h_date: 'volume_design_90_1h_date',

    design_heat_1h: 'heat_energy_design_1h',
    design_heat_24h: 'heat_energy_design_24h',
    design_heat_90_1h: 'heat_energy_design_90_1h',

    design_heat_1h_date: 'heat_energy_design_1h_date',
    design_heat_24h_date: 'heat_energy_design_24h_date',
    design_heat_90_1h_date: 'heat_energy_design_90_1h_date',

    bld_category: 'category',
    bld_year: 'year',
    bld_sec_heat: 'secondary_heating',
    a_temp: 'a_temp',
    consumption_m2: 'consumption_m2',
    epc_heat_energy_sum: 'epc_heat_energy_sum',
    epc_volume_sum: 'epc_volume_sum',
    epc_ret_temp_ft: 'epc_ret_temp_ft',
    epc_ret_temp_ut: 'epc_ret_temp_ut',
    epc_ret_temp_pt: 'epc_ret_temp_pt',
    epc_supply_temp_ft: 'epc_supply_temp_ft',
    epc_supply_temp_ut: 'epc_supply_temp_ut',
    epc_supply_temp_pt: 'epc_supply_temp_pt',
}

export const BLOCK = {
    core_monthly: CORE_MONTHLY_BLOCK,
    core_yearly: CORE_YEARLY_BLOCK,
    install_address: INSTALL_ADDRESS_BLOCK,
    epcore: EPCORE_BLOCK,
    epcore_normalized: EPCORE_NORMALIZED_BLOCK,
    epcore_normalized_monthly:EPCORE_NORMALIZED_MONTHLY_BLOCK,
    ep_peak_power: EP_PEAK_POWER_BLOCK,
    measured_peak_power: MEASURED_PEAK_POWER_BLOCK,
    location: LOCATION_BLOCK
}

export const BLOCKS = [
    'core_monthly',
    'core_yearly',
    'install_address',
    'epcore',
    'epcore_normalized',
    'ep_peak_power',
    'measured_peak_power',
    'location']

export const MONTH_INT = {
    all: null,
    jan: '00',
    feb: '01',
    mar: '02',
    apr: '03',
    may: '04',
    jun: '05',
    jul: '06',
    aug: '07',
    sep: '08',
    oct: '09',
    nov: '10',
    dec: '11',
}

export const MONTH_LBL = {
    all: 'All year',
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
}

export const MONTH_CHOICES = ['all', 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
export const MONTH_OPTIONS = new Map(MONTH_CHOICES.map(month => ([MONTH_INT[month], MONTH_LBL[month]])))
export const DUR_LATEST = 'latest_month';

export const PROP_DUR_OPTIONS = new Map([
    [DUR_LATEST, "Latest month"],
    ['latest_12_months', 'Latest 12 months'],
    ['current_calendar_year', 'Current calendar year']
])
export const PROP_CMP_OPTIONS = new Map([
    ['expected', "forecast"],
])