import React, {Component} from 'react'
import TableRow from '@material-ui/core/TableRow';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        '&:nth-child(even)': {
            backgroundColor: '#f5f7f8'
        }
    }
})

@withStyles(styles)
class UtfTableRow extends Component {
    render() {
        const {children, ...restProps} = this.props;

        return (
            <TableRow {...restProps}>
                {children}
            </TableRow>
        )
    }
}

export default UtfTableRow;