import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        color: theme.palette.primary.main
    }
})

@withStyles(styles)
class UtfCheckbox extends React.Component {
    render() {
        const {color,...restProps} = this.props;
        let style = {}
        if(color){
            style.color = color
        }
        return <Checkbox {...restProps} style={style} />
    }
}

export default UtfCheckbox;