import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';

const styles = theme => ({
    root: {
        height: theme.spacing(8),
    },
    caption: {
        ...theme.typography.h6
    },
    input: {
        marginTop: theme.spacing(.5),
        color: theme.palette.primary.main,
        ...theme.typography.h6
    },
    selectIcon: {
        color: theme.palette.primary.main,
    },
    actions: {
        color: theme.palette.primary.main,
    }
});

@withStyles(styles)
class UtfTablePagination extends Component {
    render() {
        const {...restProps} = this.props;
        return (
            <TablePagination {...restProps} />
        )
    }
}

export default UtfTablePagination;