export const DRAWER_WIDTH = 380;
export const SIDENAV_WIDTH = 260;
export const BOTTOM_ACTION_BAR_HEIGHT = 40;
export const FILTER_HEIGHT = 180;
export const MENU_WIDTH = 60;
export const BAR_HEIGHT = 56;
export const SECTION_X_SEP = 32;

export const MAIN_BAR_HEIGHT = '4.571rem'
export const TOPBAR_HEIGHT = '5.143rem'
export const PROPERTY_MAP_HEIGHT = '18rem'