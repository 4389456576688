const themes = { default:{
    palette: {
        primary: {
            main: '#1a5e8a',
            dark: '#00507a',
            light: '#4a88bb',
            contrastText: '#fff'
        },
        secondary: {
            main: '#db742b',
            light: '#4A88BB'
        },
        background: {
            paper: '#fff',
            default: '#f5f7f8'
        },
        error: {
            main: '#bb4a4a'
        },
        grey: {
            blue72: '#42494E',
            blue50: '#747B80',
            blue28: '#A7AEB3',
            blue4: '#ECEFF0',
            blue2: '#F5F7F8',
            white: '#FDFDFD'
        },
        graph: {
            blue: '#4A88BB',
            pink: '#BB4AAA',
            teal: '#4AB7BB',
            yellow: '#BBAE4A',
            purple: '#6D4ABB',
            green: '#53B84A',
            red: '#BB4A4A'
        }
    },
    typography: {
        useNextVariants: true,
        fontFamily: "Roboto",
        h1: {
            fontFamily: 'Roboto',
            fontSize: '5.714rem',
            lineHeight: 1.2,
            fontWeight: 500
        },
        h2: {
            fontFamily: 'Roboto',
            fontSize: '2.857rem',
            lineHeight: 1.2,
            fontWeight: 500,
        },
        h3: {
            fontFamily: 'Roboto',
            fontSize: '1.286rem',
            lineHeight: 1.33,
            fontWeight: 500,

        },
        h4: {
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            lineHeight: 1.71,
            fontSize: '1rem',
        },
        h5: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            lineHeight: 1.71,
            fontSize: '1rem',
        },
        h6: {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            lineHeight: 1.14,
            fontSize: '1rem',
        },
        body1: {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            lineHeight: 1.14,
            fontWeight: 300,
        },
        body2: {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            lineHeight: 1.14,
            fontWeight: 'normal',
        },
        button: {
            fontFamily: 'Roboto Condensed',
            fontSize: '.929rem',
            fontWeight: 'bold',
            fontStretch: 'condensed',
            lineHeight: 1.23
        },
        title: {
            fontFamily: 'Montserrat',
            fontSize: '1.714rem',
            fontWeight: 'bold',
            lineHeight: 1.33
        },
        subtitle1: {
            fontFamily: 'Montserrat',
            fontSize: '1.714rem',
            fontWeight: 600,
            lineHeight: 1.33
        },
        subtitle2: {
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: '1.143rem',
            lineHeight: 1.5
        },
        caption: {
            fontFamily: 'Montserrat',
            fontSize: '0.857rem',
            fontWeight: 500,
            lineHeight: 1.33
        }
    },
    overrides: {}
}}

export default themes;
