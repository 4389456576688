export default {
    'en-US': {
        switch_lang:"ÄNDRA TILL SVENSKA",
        all_properties: 'ALL PROPERTIES',
        details: 'DETAILS',
        maintenence: 'MAINTENANCE',
        map_view: 'MAP VIEW',
        trends: 'TRENDS',
        address: 'Address',
        city: 'City',
        normal_year: "Normal Year Consumption",
        energy: "Energy",
        energy_deviation:"Energy Deviation",
        energy_consumption_year:"Energy Consumption {year}",
        energy_unit:"(Energy {unit})",
        power: "Power",
        power_deviation:"Power deviation",
        power_unit: "(Power {unit})",
        return_temp: "Return Temp",
        return_temp_deviation:"Return Temp deviation",
        flow: "Flow",
        flow_deviation:"Flow deviation",
        deviation_monthly_forecast:"Deviation from monthly forecast",
        expected:"Expected",
        previous_year:"Previous year",
        row_per_page:"Row per page",
        return_temp_full:"Return temperature",
        deviation:"deviation",
        consumption:"Consumption",
        design_load:"Design Load",
        latest_month:"Latest Month",
        measured_consumption:"Measured Consumption",
        expected_consumption:"Expected Consumption",
        outdoor:"Outdoor Temperature",
        measured_value_and_trends:"Measured Values and Trends",
        compare_with:"Compare with",
        latest_12_months:"Latest 12 Months",
        current_calendar_year:"Current calendar year",
        search_addresses:"Search Addresses",
        returntemp:"Return temperature",
        supplytemp:"Supply temperature",
    },
    'sv-SE': {
        switch_lang:"SWITCH TO ENGLISH",
        all_properties: 'HELA BESTÅNDET',
        details: 'DETALJER',
        maintenence: 'ÅTGÄRDER',
        map_view: 'KARTVY',
        trends: 'TRENDER',
        address: 'Adress',
        city: 'Stad',
        normal_year: "Normalårsförbrukning",
        energy: "Energi",
        energy_unit:"(Energi {unit})",
        energy_consumption_year:"Energiförbrukning {year}",
        energy_deviation:"Energi Avvikelse",
        power: "Effekt",
        power_deviation:"Effekt Avvikelse",
        power_unit: "(Effekt {unit})",
        return_temp: "Retur temp.",
        return_temp_full:"Returtemperatur",
        return_temp_deviation:"Retur temp. Avvikelse",
        flow: "Flöde",
        flow_deviation:"Flöde Avvikelse",
        deviation_monthly_forecast:"Avvikelse från månadsprognos",
        expected:"Prognos",
        previous_year:"Föregående år",
        row_per_page:"Rader per sida",
        consumption:"Energi, normalår",
        design_load:"Dimensionerande effekt",
        latest_month:"Senaste månaden",
        measured_consumption:"Uppmätt förbrukning",
        expected_consumption:"Förväntad förbrukning",
        outdoor:"Utomhustemperatur",
        measured_value_and_trends:"Uppmätta värden och trender",
        compare_with:"Jämför med",
        latest_12_months:"Senaste 12 mån.",
        current_calendar_year:"Innevarande kalenderår",
        search_addresses:"Sök adress",
        returntemp:"Returtemperatur",
        supplytemp:"Framledningstemperatur",
    },
};