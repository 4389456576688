import React, {Component} from 'react';
import {disposeOnUnmount, inject, observer} from 'mobx-react';
import Wrapper from "../../components/layout/Wrapper";
import Box from '@material-ui/core/Box';
import Title from "./Title";
import {reaction,} from "mobx";
import {PROPERTY_MAP_HEIGHT} from "../../conf/ui_constants";
import GoogleMap from "./GoogleMap";
import SubTable from "./SubTable";
import TabSelect from "./TabSelect";
import DetailsContainer from "./DetailsContainer";

@inject('rootStore', 'sub',)
@observer
class SubDetailPage extends Component {

    componentDidMount() {
        const {sub, rootStore: {routerStore}} = this.props;
        const {params} = routerStore.routerState;

        disposeOnUnmount(this, reaction(
            () => (params.id),
            () => {
                sub.changeSubstation(params.id)
            },
            {fireImmediately: true}
        ))
        disposeOnUnmount(this, reaction(
            () => ({sub: sub.substation, blocks: sub.requiredBlocks()}),
            async (data) => {
                if (data.sub) {
                    await sub.getSubstationData(data.blocks)
                }
            },
            {fireImmediately: true}
        ))
        disposeOnUnmount(this, reaction(
            () => sub.fetchedBlocks,
            () => {
                sub.updateSubDetailData()
            }
        ))
    }

    render() {
        const {sub} = this.props;
        return (
            <Wrapper>
                <Box mt={6} mx={12} mb={6}>
                    <Title/>
                    <div style={{height: PROPERTY_MAP_HEIGHT}}>
                        <GoogleMap/>
                    </div>
                    <SubTable/>
                    <Box mt={1}/>
                    <div style={{width: '40%'}}>
                        <TabSelect/>
                    </div>
                    <Box mt={1} height={660} display="flex" flexDirection="row" width="100%">
                        { sub.sub_detail_tab === "details" && <DetailsContainer/>
                        }
                        {
                            sub.sub_detail_tab === "maintenence" && <Box height="100%" width="100%" bgcolor="white" />
                        }
                    </Box>
                </Box>
            </Wrapper>
        );
    }
}

export default SubDetailPage;