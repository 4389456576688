import React, {Component} from 'react'
import {inject, observer,} from 'mobx-react';
import CentrallyAlignedRow from '../../components/layout/CentrallyAlignedRow';
import {withTheme} from '@material-ui/core/styles';
import Tabs from '../../components/ui/UtfTabs';
import Tab from '../../components/ui/UtfTab';
import {FormattedMessage} from 'react-intl';
import ComparisonTable from './ComparisonTable';

@inject('ui')
@withTheme
@observer
class DetailSection extends Component {
    onChangeTab = (e, value) => {
        const {ui} = this.props;
        ui.changePropertiesTab(value)
    }

    render() {
        const {ui, theme} = this.props;

        return (
            <div style={{marginTop: theme.spacing(6), width: '100%'}}>
                    <CentrallyAlignedRow bgcolor="white" flexGrow={1}>
                        <div style={{width: '100%'}}>
                            <Tabs
                                value={ui.current_properties_tab}
                                variant="fullWidth"
                                onChange={this.onChangeTab}>
                                <Tab label={<FormattedMessage id="trends"/>} value="trends"/>
                                <Tab label={<FormattedMessage id="map_view"/>} value="map_view"/>
                                <Tab label={<FormattedMessage id="details"/>} value="details"/>
                                <Tab label={<FormattedMessage id="maintenence"/>} value="maintenence"/>
                            </Tabs>
                        </div>
                    </CentrallyAlignedRow>

                <div style={{marginTop: theme.spacing(1), width: '100%', minHeight: theme.spacing(40)}}>
                    {ui.current_properties_tab === "trends" && <ComparisonTable/>}
                </div>
            </div>
        )
    }
}

export default DetailSection;