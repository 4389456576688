import React from 'react';

class LogoText extends React.PureComponent {
    render() {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg" width="134.061" height="40.823" version="1.1"
            >
                <path id="prefix__Path_987"
                      d="M30.003 21.821h.016V18.88h-4.287v2.942a6.588 6.588 0 11-13.175 0h.017V18.88h-4.29v2.942a10.859 10.859 0 1021.717 0z"
                      data-name="Path 987" fill="#fff" fillOpacity="1"
                      fillRule="evenodd"/>
                <path
                    d="M125.991 15.391h2.502v14.575h-2.502zm0-5.51h2.502v2.79h-2.502zm-8.43 11.854l-3.049-8.873-3.24 8.873zm-4.47-11.853h3.075l7.287 20.084h-2.98l-2.037-6.016h-7.944l-2.173 6.016h-2.79zm1.407 0zm-17.344 18.43q1.722 0 2.857-1.436 1.149-1.45 1.149-4.32 0-1.75-.506-3.008-.957-2.42-3.5-2.42-2.557 0-3.5 2.556-.506 1.367-.506 3.473 0 1.695.506 2.885.957 2.27 3.5 2.27zm-6.371-12.92h2.392v1.94q.739-.997 1.614-1.544 1.244-.82 2.925-.82 2.489 0 4.225 1.914 1.736 1.9 1.736 5.441 0 4.785-2.502 6.836-1.586 1.299-3.691 1.299-1.654 0-2.775-.725-.657-.41-1.463-1.408v7.478h-2.461zM80.835 28.38q2.447 0 3.35-1.846.915-1.86.915-4.129 0-2.05-.656-3.336-1.039-2.023-3.582-2.023-2.256 0-3.281 1.722-1.026 1.723-1.026 4.157 0 2.337 1.026 3.896 1.025 1.559 3.254 1.559zm.095-13.48q2.83 0 4.786 1.886 1.955 1.887 1.955 5.55 0 3.542-1.723 5.852-1.723 2.31-5.346 2.31-3.021 0-4.799-2.036-1.777-2.051-1.777-5.496 0-3.692 1.873-5.88Q77.772 14.9 80.93 14.9zm-.082.067zm-15.471.356h2.338v2.53q.287-.739 1.408-1.792 1.121-1.066 2.584-1.066.068 0 .233.014.164.013.56.054v2.598q-.219-.041-.41-.055-.178-.014-.397-.014-1.859 0-2.857 1.204-.998 1.19-.998 2.748v8.422h-2.461zM46.829 9.882h9.038q2.68 0 4.32 1.517 1.64 1.504 1.64 4.238 0 2.352-1.462 4.102-1.463 1.736-4.498 1.736H49.55v8.49h-2.72zm12.25 5.77q0-2.216-1.64-3.009-.903-.424-2.475-.424H49.55v6.96h5.414q1.832 0 2.967-.78 1.148-.78 1.148-2.748z"
                    id="path828" fill="#fff" fillOpacity="1"/>
                <path id="prefix__Path_988"
                      d="M21.103 7.172V.028l.2.016a21.29 21.29 0 0118.42 14.335l.275.806h-7.754a13.92 13.92 0 00-11.141-8.013z"
                      data-name="Path 988" fill="#db742b" fillRule="evenodd"/>
                <path id="prefix__Path_989"
                      d="M0 21.661a19.181 19.181 0 1038.113-3.1h-4.986a14.3 14.3 0 01-.5 7.517 14.148 14.148 0 01-27.409-2.137v-.017l-.018-.107A14.224 14.224 0 0117.63 7.278V2.534A19.188 19.188 0 000 21.661z"
                      data-name="Path 989" fill="#fff" fillRule="evenodd"/>
            </svg>
        );
    }
}

export default LogoText;