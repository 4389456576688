import {action, flow, observable} from 'mobx';
import {BLOCK} from "../conf/constants";
import {deviation_pct, getActiveYM} from './utils'
import addMonths from "date-fns/addMonths";
import {formatNumber, getBoundary} from "../core/utils";
import {METRICS, OPTION_META, OPTIONS} from "../pages/SubDetailPage/Constants";
import {MEASURED_PEAK_POWER_BLOCK} from "../conf/blocks";

class SubStore {
    @observable is_fetching = false;

    @observable resource_type = "substation";
    @observable substation = null;

    @observable graph_min_date = new Date(2015, 0, 1); // TODO: should be current date minus one year
    @observable graph_max_date = new Date(2017, 12, 31); // TODO: should be current date
    @observable min_training_date = new Date(2017, 0, 1);
    @observable max_training_date = new Date(2017, 12, 31);

    @observable sub_detail_tab = "details"
    @observable graph_metric = "energy";

    @observable graph_metric_option = {
        measured_consumption: true,
        expected_consumption: true,
        outdoor: true,
    }

    @observable fetchedBlocks = null;
    @observable detail = {};

    @observable fetchedGraphData = null;
    @observable is_fetching_graphs = false;
    @observable graphSeries = [];
    @observable graphYAxis = [];

    getSubstationData = flow(function* (blocknames) {
        try {
            this.is_fetching = true;
            let blocks = yield this.parent.ibs.getInfoBlocks({
                resource_type: 'substation',
                resource_id: this.substation,
                filter_by: 'substation',
                block_names: blocknames
            })
            this.fetchedBlocks = blocks;
        } catch (err) {
            console.log("unable to get substation data", err)
        } finally {
            this.is_fetching = false;
        }
    })
    getGraphData = flow(function* () {
        /*
        get EPO and meter data and merge them and feed to graph.
         */
        let components = ['heat_energy', 'volume', 'returntemp', 'supplytemp'];
        this.graphSeries = [];
        this.is_fetching_graphs = true
        try {
            const data = yield Promise.all([
                this.parent.ibs.getMeterData({
                    resource_type: this.resource_type,
                    resource_id: this.substation,
                    components: components.join(','),
                    date_min: getBoundary({pos: "start", idate: this.graph_min_date}),
                    date_max: getBoundary({pos: "end", idate: this.graph_max_date})
                }),
                this.parent.ibs.getEPOData({
                    resource_type: this.resource_type,
                    resource_id: this.substation,
                    mt_start_date: getBoundary({
                        pos: "start",
                        idate: this.min_training_date
                    }),
                    mt_end_date: getBoundary({
                        pos: "end",
                        idate: this.max_training_date
                    }),
                    cs_start_date: getBoundary({
                        pos: "start",
                        idate: this.graph_min_date,
                    }),
                    cs_end_date: getBoundary({
                        pos: "end",
                        idate: this.graph_max_date
                    })
                })
            ])
            const combined_blocks = {
                meter_data: data[0].meter_data,
                ep_simulation: data[1].ep_simulation
            };
            this.fetchedGraphData = combined_blocks;
        } catch (err) {
            console.log("getting meter data error", err)
        }finally{
            this.is_fetching_graphs = false
        }
    })

    @action.bound
    processGraphData(){
        /*
        create graph series from fetch api data.
         */

        let activeSeries = [];
        let activeYaxis = [];
        let yAxisNo = 0;
        let reader;
        let currentSeries;
        if(this.graph_metric_option.measured_consumption || this.graph_metric_option.expected_consumption){
            activeYaxis.push(
                {
                    labels:{
                        align:"left",
                        color:OPTION_META.measured_consumption.color,
                        x:-20
                    },
                    lineWidth:1,
                    tickWidth: 1,
                    tickLength: 50,
                    opposite:false,
                    title: {
                        text: `${OPTIONS.get(this.graph_metric)} consumptions ${METRICS[this.graph_metric].unit}`,
                        margin:30
                    },
                }
            )
        }
        if(this.graph_metric_option.measured_consumption){
            reader = this.parent.ibs.getBlockReader(this.fetchedGraphData,[
                ['meter_data$timestamp','t'],
                [`meter_data$${METRICS[this.graph_metric].col}`,'measured']
            ])
            currentSeries = []
            for(let [ts,] of this.fetchedGraphData.meter_data.idxMap){
                currentSeries.push(reader(ts))
            }
            activeSeries.push({
                type: 'line',
                name: `Measured Consumption`,
                color:OPTION_META.measured_consumption.color,
                pointStart: currentSeries[0][0],
                pointInterval: 3600000,
                data: currentSeries,
                yAxis:yAxisNo,
                tooltip: {
                    valueSuffix:METRICS[this.graph_metric].unit,
                    pointFormatter:function(){
                        return  `<span style="color:${this.color}">●</span> ${this.series.name}:<b> ${formatNumber(this.y)} ${this.series.tooltipOptions.valueSuffix}</b><br/>`;
                    },
                },
            })
        }
        if(this.graph_metric_option.expected_consumption){
            reader = this.parent.ibs.getBlockReader(this.fetchedGraphData,[
                ['meter_data$timestamp','t'],
                [`ep_simulation$${METRICS[this.graph_metric].col}`,'expected']
            ])
            currentSeries = []
            for(let [ts,] of this.fetchedGraphData.meter_data.idxMap){
                currentSeries.push(reader(ts))
            }
            activeSeries.push({
                type: 'line',
                name: `Expected consumption`,
                color:OPTION_META.expected_consumption.color,
                pointStart: currentSeries[0][0],
                pointInterval: 3600000,
                data: currentSeries,
                yAxis:yAxisNo,
                tooltip: {
                    valueSuffix:METRICS[this.graph_metric].unit,
                    pointFormatter:function(){
                        return  `<span style="color:${this.color}">●</span> ${this.series.name}:<b> ${formatNumber(this.y)} ${this.series.tooltipOptions.valueSuffix}</b><br/>`;
                    },
                },
            })
        }
        if(this.graph_metric_option.measured_consumption || this.graph_metric_option.expected_consumption){
            yAxisNo += 1
        }
        if(this.graph_metric_option.outdoor){
            reader = this.parent.ibs.getBlockReader(this.fetchedGraphData,[
                ['meter_data$timestamp','t'],
                [`ep_simulation$${METRICS.outdoor.col}`,'outdoor']
            ])
            currentSeries = []
            for(let [ts,] of this.fetchedGraphData.meter_data.idxMap){
                currentSeries.push(reader(ts))
            }
            activeYaxis.push(
                {
                    labels:{
                        align:"right",
                        color:OPTION_META.outdoor.color
                    },
                    lineWidth:1,
                    tickWidth: 1,
                    tickLength: 50,
                    opposite:false,
                    title: {
                        text: `Outdoor Temp ${METRICS.outdoor.unit}`,
                        margin:0
                    },
                }
            )
            activeSeries.push({
                type: 'line',
                name: `Outdoor`,
                pointStart: currentSeries[0][0],
                color:OPTION_META.outdoor.color,
                pointInterval: 3600000,
                data: currentSeries,
                yAxis:yAxisNo,
                tooltip: {
                    valueSuffix:METRICS.outdoor.unit,
                    pointFormatter:function(){
                        return  `<span style="color:${this.color}">●</span> ${this.series.name}:<b> ${formatNumber(this.y)} ${this.series.tooltipOptions.valueSuffix}</b><br/>`;
                    },
                },
            })
        }
        this.graphSeries = activeSeries;
        this.graphYAxis = activeYaxis;
    }

    constructor(parent) {
        this.parent = parent;

        this.requiredBlocks = this.requiredBlocks.bind(this);
        this.getDetailColSpec = this.getDetailColSpec.bind(this);
        this.updateSubDetailData = this.updateSubDetailData.bind(this);
    }

    @action.bound
    selectGraphMetric(metric) {
        this.graph_metric = metric;
    }

    @action.bound
    selectGraphSeries(series, select) {
        this.graph_metric_option[series] = select;
    }

    @action.bound
    changeSubstation(new_substation) {
        if (this.substation !== new_substation) {
            this.substation = new_substation;
        }
    }

    @action.bound
    changeSubTab(tabname) {
        this.sub_detail_tab = tabname;
    }

    requiredBlocks() {
        const [year, month] = getActiveYM()
        const sdate = addMonths(new Date(year, month, 15), -1)
        const blockParams = {
            year: year,
            month: month,
            interval: "1",
            syear: sdate.getFullYear(),
            smonth: sdate.getMonth()
        }
        let blockNames = [
            'epcore_normalized',
            'epcore',
            'install_address',
            'core_monthly',
            'location',
            'ep_peak_power',
            'measured_peak_power'].map(blkName => {
            return BLOCK[blkName].to_block_name(blockParams)
        });
        blockNames.push(MEASURED_PEAK_POWER_BLOCK.to_block_name({hist:"12month",...blockParams}))
        return blockNames;
    }

    getDetailColSpec() {
        const [year, month] = getActiveYM()
        const sdate = addMonths(new Date(year, month, 15), -1)
        const blockParams = {
            year: year,
            month: month,
            interval: "1",
            syear: sdate.getFullYear(),
            smonth: sdate.getMonth()
        }
        const install_address = BLOCK.install_address.to_block_name(blockParams)
        const epcore_normalized = BLOCK.epcore_normalized.to_block_name(blockParams)
        const epcore = BLOCK.epcore.to_block_name(blockParams)
        const core_monthly = BLOCK.core_monthly.to_block_name(blockParams)
        const ep_peak_power = BLOCK.ep_peak_power.to_block_name(blockParams)
        const measured_peak_power = BLOCK.measured_peak_power.to_block_name(blockParams)
        const measured_peak_power_yearly = BLOCK.measured_peak_power.to_block_name({hist:"12month",...blockParams})
        return [
            [`${install_address}$resource_name`, 'id'],
            [`${install_address}$street`, 'address'],
            [`${install_address}$region`, 'city'],
            [`${epcore_normalized}$heat_energy_sum`, 'normal_year'],
            [`${core_monthly}$heat_energy_sum`, 'energy'],
            [`${epcore}$heat_energy_sum`, 'ep_energy'],
            [`${core_monthly}$volume_sum`, 'flow'],
            [`${epcore}$volume_sum`, 'ep_flow'],
            [`${core_monthly}$returntemp_flowweighted_avg`, 'return_temp'],
            [`${epcore}$returntemp_flowweighted_avg`, 'ep_return_temp'],
            [function (row) {
                return deviation_pct(row, 'energy', 'ep_energy')
            }, 'energy_deviation'],
            [function (row) {
                return deviation_pct(row, 'return_temp', 'ep_return_temp')
            }, 'return_temp_deviation'],
            [function (row) {
                return deviation_pct(row, 'flow', 'ep_flow')
            }, 'flow_deviation'],
            [`${ep_peak_power}$avg_peak_power`, 'power'],
            [`${measured_peak_power}$avg_peak_power`, 'm_power'],
            ["location$coordinates", 'coordinates'],
            [function (row) {
                return deviation_pct(row, 'm_power', 'power')
            }, 'power_deviation'],
            [`${measured_peak_power_yearly}$avg_peak_power`,'design_load']
        ]
    }

    @action.bound
    updateSubDetailData() {
        const blockReader = this.parent.ibs.getBlockReaderJson(this.fetchedBlocks, this.getDetailColSpec())
        this.detail = blockReader(this.substation)
    }
}

export default SubStore;