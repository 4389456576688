import { computed, flow, observable } from 'mobx';
import createAuth0Client from "@auth0/auth0-spa-js";
import { RouterState } from 'mobx-state-router';

class Session {

    @observable token;

    @observable msg = null;

    constructor(parent) {
        this.parent = parent;
        this.audience = process.env.AUTH0_AUDIENCE;
        this.domain = process.env.AUTH0_DOMAIN;
        this.client_id = process.env.AUTH0_CLIENTID;
        this.redirect_uri = window.location.origin;
        this.client = null;


        this.checkSession = this.checkProdSession.bind(this);

        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);

        this.authContext = {
            domain: this.domain,
            client_id: this.client_id,
            redirect_uri: this.redirect_uri,
            responseType: 'token id_token',
            audience: this.audience
        };
    }


    @computed get isAuthenticated() {
        return this.token !== null && this.token !== ''
    }

    checkProdSession = flow(function* () {
        if (!this.client) {
            this.client = yield createAuth0Client(this.authContext);
        }
        return this.client.isAuthenticated();
    })

    checkDevSession = flow(function* () {
        yield true;
        return true;
    })

    login = flow(function* () {
        this.msg = null;
        this.client = yield createAuth0Client(this.authContext);
        try {
            yield this.client.loginWithPopup();
        } catch (err) {
            if (err.error === "unauthorized") {
                this.msg = err.error_description
            }
        }
        let ret = yield this.client.getTokenSilently();
        this.token = ret;
        this.parent.routerStore.goTo(new RouterState("dash"));
    })


    authHeaders = flow(function* () {
        if (this.token) {
            return { 'authorization': 'Bearer ' + this.token }
        } else {

            if (!this.client) {
                this.client = yield createAuth0Client(this.authContext);
            }
            this.token = yield this.client.getTokenSilently();
            return { 'authorization': 'Bearer ' + this.token }
        }

    })


    logout = flow(function* () {
        if (!this.client) {
            this.client = yield createAuth0Client(this.authContext);
        }
        this.client.logout({ returnTo: window.location.origin })
        yield this.parent.clearCache()
    })


}

export default Session;