import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        height: theme.spacing(8),
        flexGrow: 1,
        display: 'flex',
        backgroundColor: '#fff',
        flexShrink: 0,
        justifyContent: "space-between",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        alignItems: 'center',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)'

    },
    root_err: {
        backgroundColor: theme.palette.error.main,
        color: '#fff'
    }
}));

export default function HeadingBar(props) {
    const classes = useStyles();
    return (
        <div className={clsx(classes.root, props.err && classes.root_err)}>
            {props.children}
        </div>
    );
}