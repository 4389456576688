import Messages from './messages';
import {IntlProvider} from "react-intl";
import React from "react";
import {inject, observer} from "mobx-react";

function MbxLocaleProvider({rootStore, children,...restProps}){
    const locale = rootStore.locale.value;
    const messages =  Messages[locale];
    return <IntlProvider locale={locale}
                        key={locale}
                        messages={messages}
        {...restProps}
    >
        {children}
    </IntlProvider>
}

const localeProvider = inject('rootStore')(observer(MbxLocaleProvider))
export default localeProvider;