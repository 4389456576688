import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import {RouterState} from 'mobx-state-router';

@inject('rootStore')
@observer
class NotFound extends Component {
    render() {
        const {rootStore: {routerStore}} = this.props;
        return (
            <Box
                display="flex"
                height="100%"
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
            >
                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                    <Typography variant="h1" color="secondary">404</Typography>
                    <Typography variant="h4" color="secondary">NOT FOUND</Typography>
                    <Box mt={4}>
                        <Button
                            onClick={() => {
                                routerStore.goTo(new RouterState('dash'));
                            }}
                            variant="contained"
                            color="primary">
                            BACK TO HOME
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default NotFound;