import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import {formatNumber} from '../../core/utils';
import {isVal} from '../../stores/utils';

const styles = theme => ({
    root_dec: {
        marginLeft: theme.spacing(1),
        color: theme.palette.success.main,
    },
    root_inc: {
        marginLeft: theme.spacing(1),
        color: theme.palette.error.main
    },
    nodata: {
        color: theme.palette.grey.blue50
    }
});

@withStyles(styles)
class DeviationCell extends Component {
    render() {
        const {row, colname, classes} = this.props;
        const divPositive = row[`${colname}_deviation`];
        const clsDiv = clsx( divPositive >= 0 ? classes.root_inc : classes.root_dec);
        if (isVal(divPositive) && isVal(row[colname])) {
            return (
                <Typography component="span" variant="body2" color="textPrimary">
                    <span>{formatNumber(row[colname])}</span>
                    <span className={clsDiv}>
                        ({formatNumber(divPositive)}%)
                    </span>
                </Typography>)
        } else {
            return <Typography component="span" variant="body2" className={classes.nodata}>NoData</Typography>
        }

    }
}

export default DeviationCell;