import React from "react";
import {inject, observer} from "mobx-react";
import {MuiThemeProvider} from '@material-ui/core/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import {svSE} from '@material-ui/core/locale';
import {enUS} from '@material-ui/core/locale';

import Themes from './themes';

function MbxThemeProvider({rootStore, children,...restProps}){
    const locale = rootStore.locale.value;
    const themeLocale = locale === 'sv-SE'?svSE:enUS;
    const theme = createMuiTheme(Themes[rootStore.ui.theme],themeLocale);
    return <MuiThemeProvider
            theme={theme}
            {...restProps}
        >
        {children}
    </MuiThemeProvider>
}

const themeProvider = inject('rootStore')(observer(MbxThemeProvider))
export default themeProvider;