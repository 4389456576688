import React, {Component, Fragment} from 'react';
import {inject} from 'mobx-react';
import {RouterView} from 'mobx-state-router';

import routeMappings from '../../conf/route_mappings';

class RouterBase extends Component {
    render() {
        const {rootStore: {routerStore}} = this.props;
        return (
            <Fragment>
                <RouterView routerStore={routerStore} viewMap={routeMappings}/>
            </Fragment>
        );
    }
}

const Router = inject('rootStore')(RouterBase);
export default Router;
