import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { inject, observer } from 'mobx-react';

@inject('rootStore')
@observer
class LoginPage extends React.Component {


    render() {
        const { rootStore: { session } } = this.props;
        return (
            <Box display="flex" height="100vh" width="100%" justifyContent="center" alignItems="center">
                <Box display="flex" flexDirection="column" justifyContent="center">
                    <img src='/logo-animated.gif' alt="logo" />
                    <Button variant="contained" onClick={session.login} color="primary">Login</Button>
                    <Box mt={2}>
                        <Typography variant="h6" color="secondary">
                            {session.msg}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default LoginPage;