import React, {Component} from 'react'
import {inject, observer,} from 'mobx-react';
import {withStyles} from '@material-ui/core/styles';
import ButtonDropdown from '../../components/ui/ButtonDropDown';
import Typography from "@material-ui/core/Typography";
import {PROP_CMP_OPTIONS, PROP_DUR_OPTIONS} from '../../conf/constants';
import {Box,InputBase} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import {FormattedMessage,injectIntl} from "react-intl";

const styles = theme => ({
    root:{
        height:theme.spacing(10)
    },
    ico: {
        marginLeft: theme.spacing(1),
        color: theme.palette.grey[700]
    },
    srch:{
        display:"flex",
        flexDirection:"row",
        alignItems:"center",
        height:theme.spacing(6),
        backgroundColor:theme.palette.grey.blue2,
        width:theme.spacing(40),
        marginLeft:theme.spacing(1)
    },
    input:{
        marginLeft: theme.spacing(1),
        display:"flex",
        flexDirection:"row",
        flexGrow:1
    },
    iconButton:{
        padding: 10,
    }
});

const DISABLED_DURATION = new Set(['latest_12_months', 'current_calendar_year']);
const DISABLED_COMPARE = new Set(['peak_power', 'epc_ret_temp_ft', 'volume_sum'])

@inject('properties')
@withStyles(styles)
@injectIntl
@observer
class Form extends Component {
    onChangeDuration = (value) => {
        const {properties} = this.props;
        properties.changeDuration(value);
    }
    onChangeCompare = (value) => {
        const {properties} = this.props;
        properties.changeCompareWith(value)
    }

    render() {
        const {classes,intl, properties, store, srchProp,updateSearchInput} = this.props;
        return (
            <Box
                display="flex"
                alignItems="center"
                mt={1}
                px={2}
                zIndex="tooltip"
                boxShadow={4}
                bgcolor="white"
                className={classes.root}
                >
                <Typography variant="h6">
                    <FormattedMessage id="measured_value_and_trends" />
                </Typography>
                <Box display="flex" flexGrow={1}/>
                <div style={{marginLeft: 8}}>
                    <ButtonDropdown
                        options={PROP_DUR_OPTIONS}
                        selectedValue={properties.duration}
                        disabled={DISABLED_DURATION}
                        onChange={this.onChangeDuration}
                    />
                </div>
                <div style={{marginLeft: 8}}>
                    <ButtonDropdown
                        options={PROP_CMP_OPTIONS}
                        selectedValue={properties.compare_with}
                        onChange={this.onChangeCompare}
                        optionPrefix={<FormattedMessage id="compare_with"/>}
                        disabled={DISABLED_COMPARE}
                    />
                </div>
                <div className={classes.srch}>
                    <InputBase
                        className={classes.input}
                        placeholder={intl.formatMessage({id:"search_addresses"})}
                        value={store[srchProp]||''}
                        onChange={updateSearchInput}
                    />
                    <IconButton className={classes.iconButton}>
                        <SearchIcon/>
                    </IconButton>
                </div>
            </Box>
        )
    }
}


export default Form;