import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './service_worker';
import {configure} from 'mobx';
import App from './app';
import Numeral from 'numeral';
import './app.css';

require('typeface-roboto')
require('typeface-roboto-condensed')
require('typeface-montserrat')

configure({enforceActions: "observed"})
Numeral.register('locale', 'fr', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
});
Numeral.locale('fr');

ReactDOM.render(
    <App/>,
    document.getElementById("root")
);

serviceWorker.register();