import {RouterState} from 'mobx-state-router';


const checkForUserSignedIn = async (fromState, toState, routerStore) => {
    const {
        rootStore: {session}
    } = routerStore;
    let is_logged_in = await session.checkSession();
    window.scrollTo(0,0);
    if (is_logged_in) {
        return true
    } else {
        throw new RouterState('login');
    }
};

export const routes = [
    {
        name: 'dash',
        pattern: '/',
        beforeEnter: checkForUserSignedIn

    },
    {
        name: 'login',
        pattern: '/login'
    },
    {
        name: 'notFound',
        pattern: '/not-found',
    },
    {
        name: 'subdetail',
        pattern: '/sub/:id',
        beforeEnter: checkForUserSignedIn
    },
];