import React from 'react';
import DashPage from '../pages/DashPage';
import LoginPage from '../pages/LoginPage';
import NotFoundPage from '../pages/NotFound';
import SubDetailPage from '../pages/SubDetailPage';

const routeMappings = {
    login: <LoginPage/>,
    dash: <DashPage/>,
    notFound: <NotFoundPage/>,
    subdetail: <SubDetailPage/>,
}

export default routeMappings;
