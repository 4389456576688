import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        width: theme.spacing(1)
    }
})

function VSep(props) {
    const {classes} = props;

    return <div className={classes.root}/>
}

export default withStyles(styles)(VSep)
