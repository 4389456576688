import React from 'react';
import {inject, observer} from "mobx-react";
import Tabs from "../../components/ui/UtfTabs";
import Tab from "../../components/ui/UtfTab";
import {FormattedMessage} from "react-intl";
import Paper from "@material-ui/core/Paper";

@inject('sub')
@observer
class TabSelect extends React.Component {

    onChangeTab = (e, v) => {
        const {sub} = this.props;
        sub.changeSubTab(v)
    }

    render() {
        const {sub} = this.props;

        return (
            <Paper square>
                <Tabs
                    variant="fullWidth"
                    value={sub.sub_detail_tab}
                    onChange={this.onChangeTab}>
                    <Tab label={<FormattedMessage id="details"/>} value="details"/>
                    <Tab label={<FormattedMessage id="maintenence"/>} value="maintenence"/>
                </Tabs>
            </Paper>
        );
    }
}

export default TabSelect;