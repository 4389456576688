import {RouterState, RouterStore} from 'mobx-state-router';
import {routes} from '../conf/routes';
import UiStore from './ui_store';
import Session from './session';
import ApiProvider from './api_provider';
import InfoBlockStore from './ibs';
import PropertiesStore from './properties';
import SubStore from './sub_store';
import Cache from './cache';
import LocaleStore  from "./localeStore";
import { APP_VERSION} from "../conf/conf";

const notFound = new RouterState('notFound');


const USING_CACHE = ['ibs', 'properties']

class RootStore {
    constructor() {
        this.cache = new Cache(this);
        this.api = new ApiProvider(this);
        this.ibs = new InfoBlockStore(this);
        this.session = new Session(this);
        this.routerStore = new RouterStore(this, routes, notFound);
        this.ui = new UiStore(this);
        this.properties = new PropertiesStore(this);
        this.sub = new SubStore(this);
        this.locale = new LocaleStore(this,'en-US');
        this.startup = this.startup.bind(this);
    }

    async startup() {
        console.log("APPLICATION VERSION", APP_VERSION)
        //await this.filters.loadFromCache();
        //await this.networks.check()
        //await this.networks.keepFilteredUpdated();
        //await this.networks.keepClustersFiltered();
        // await this.properties.startup();
    }

    async clearCache() {
        for (let store of USING_CACHE) {
            this[store].clearCache()
        }
    }
}

export default new RootStore();