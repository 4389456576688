import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import TopNav from '../app/TopNav';

const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        height: '100%',
        flexDirection: 'column'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    }
});

@withStyles(styles)
class Wrapper extends React.Component {
    render() {
        const {classes, offset = true} = this.props;
        return (
            <div className={classes.root}>
                <TopNav/>
                {offset && <div className={classes.toolbar}/>}
                {this.props.children}
            </div>);
    }
}

export default Wrapper; 