import React from 'react';
import GoogleMapReact from 'google-map-react';
import {inject, observer} from "mobx-react";
import Skeleton from "@material-ui/lab/Skeleton";
import Marker from "../../components/maps/Marker";
import CONF from "../../conf/conf";

@inject('sub')
@observer
class GoogleMap extends React.Component {
    render() {
        const {sub} = this.props;
        if (sub.detail.coordinates) {
            return (
                <GoogleMapReact
                    defaultZoom={15}
                    defaultCenter={{
                        lat: sub.detail.coordinates.lat,
                        lng: sub.detail.coordinates.lon
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{key: CONF.gmaps_key}}
                >
                    <Marker
                        lat={sub.detail.coordinates.lat}
                        lng={sub.detail.coordinates.lon}/>
                </GoogleMapReact>);
        } else {
            return (
                <Skeleton variant="rect" height="100%"/>
            );
        }

    }
}

export default GoogleMap;