import React, {Component} from 'react'
import {observable,action,reaction} from 'mobx';
import {disposeOnUnmount, inject, observer} from 'mobx-react';
import {RouterState} from 'mobx-state-router';
import {Typography} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import RowCell from '../../components/ui/RowCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '../../components/ui/UtfTableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '../../components/ui/UtfTablePagination';
import TableRow from '../../components/ui/UtfTableRow';
import Paper from '@material-ui/core/Paper';
import DeviationValue from '../../components/analytics/DeviationValue';
import NumericalCell from '../../components/analytics/NumericalCell';
import {TABLE_COLUMNS} from "./constants";
import Form from "./Form";

@inject('rootStore', 'properties')
@observer
class ComparisonTable extends Component {
    @observable search_input = ""
    @observable tableRows = [];
    @observable paginatedRows = [];

    @action.bound
    updateSearchInput(e){
        this.search_input = e.target.value;
    }

    componentDidMount(){
        const {properties} = this.props;
        disposeOnUnmount(this,reaction(
            ()=> properties.tableData.map(r=>r.id),
            ()=>{
                if(this.search_input){
                    this.tableRows = properties.tableData.filter(r=>r.address.toLowerCase().includes(this.search_input.toLowerCase()))
                }else{
                    this.tableRows = properties.tableData
                }
            },{
                fireImmediately:true
            }
        ))
        disposeOnUnmount(this,reaction(
            ()=>{
                return [this.tableRows.length,properties.pageNo,properties.rowsPerPage]
            },
            ()=>{
                const pageNo = parseInt(Math.min(this.tableRows.length/properties.rowsPerPage,properties.pageNo))
                this.paginatedRows = this.tableRows.slice(
                    pageNo * properties.rowsPerPage,
                    (pageNo + 1) * properties.rowsPerPage)
            },
            {
                fireImmediately:true
            }
        ))
         disposeOnUnmount(this,reaction(
            ()=> this.search_input,
            ()=>{
                this.tableRows = properties.tableData.filter(r=>r.address.toLowerCase().includes(this.search_input.toLowerCase()))
            },
             {fireImmediately:false,delay:1000}
        ))
    }

    render() {
        const {properties, rootStore: {routerStore}} = this.props;
        return (
            <>
                <Form updateSearchInput={this.updateSearchInput} store={this} srchProp="search_input"/>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead columns={TABLE_COLUMNS} store={properties}/>
                        <TableBody>
                            {this.paginatedRows.map((row) => (
                                <TableRow key={row.id}>
                                    <RowCell column={TABLE_COLUMNS[0]}
                                             current={row}
                                             onClick={() => {
                                                 routerStore.goTo(new RouterState("subdetail", {id: row.id}))
                                             }}
                                             style={{cursor: "pointer"}}
                                    >
                                        <Typography variant="body2" color="primary">{row.address}</Typography>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[1]} current={row}>
                                        <Typography variant="body2">{row.city}</Typography>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[2]} current={row}>
                                        <NumericalCell row={row} colname="normal_year"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[3]} current={row}>
                                        <NumericalCell row={row} colname="energy"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[4]} current={row}>
                                        <DeviationValue row={row} colname="energy_deviation"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[5]} current={row}>
                                        <NumericalCell row={row} colname="power"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[6]} current={row}>
                                        <DeviationValue row={row} colname="power_deviation"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[7]} current={row}>
                                        <NumericalCell row={row} colname="return_temp"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[8]} current={row}>
                                        <DeviationValue row={row} colname="return_temp_deviation"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[9]} current={row}>
                                        <NumericalCell row={row} colname="flow"/>
                                    </RowCell>
                                    <RowCell column={TABLE_COLUMNS[10]} current={row}>
                                        <DeviationValue row={row} colname="flow_deviation"/>
                                    </RowCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    count={this.tableRows.length}
                                    rowsPerPage={properties.rowsPerPage}
                                    page={parseInt(Math.min(this.tableRows.length/properties.rowsPerPage,properties.pageNo))}
                                    onChangePage={properties.changePageNo}
                                    onChangeRowsPerPage={properties.changeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default ComparisonTable;