import React, { Component } from 'react';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import Box from '@material-ui/core/Box';

import Wrapper from '../../components/layout/Wrapper';
import KpiSection from './KpiSection';
import DetailSection from './DetailSection';
import HeadingBar from '../../components/app/HeadingBar';
import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';

@inject('rootStore', 'properties')
@observer
class DashPage extends Component {
    componentDidMount() {
        const { properties } = this.props;
        properties.onLogin()
        disposeOnUnmount(this, reaction(
            () => {
                let c = properties.requiredBlocksForComparisonTable()
                c.push(properties.all_properties ? properties.all_properties.length : 0)
                return c
            },
            async () => {
                let blockNames = properties.requiredBlocksForComparisonTable()
                await properties.getTableData(blockNames)
            }
        ))
        disposeOnUnmount(this, reaction(
            () => {
                return [properties.fetchedBlocks, properties.sortId, properties.sortDirection]
            },
            () => {
                properties.sortTableData()
            }
        ))
    }

    render() {
        const { properties } = this.props;
        return (
            <Wrapper>
                <Box mx={12} mb={6}>
                    {
                        properties.err_msg
                        && <Box mt={1}>
                            <HeadingBar err={true}>
                                <Typography variant="body1">{properties.err_msg}</Typography>
                                <IconButton onClick={properties.clearMsg}>
                                    <CloseIcon />
                                </IconButton>
                            </HeadingBar>
                        </Box>
                    }
                    <KpiSection />
                    <DetailSection />
                </Box>
            </Wrapper>
        );
    }
}

export default DashPage;