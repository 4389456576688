export const OPTIONS = new Map([
    ["energy", "Energy"],
    ["flow", "Flow"],
    ["returntemp","Return Temperature"],
    ["supplytemp","Supply Temperature"],
])

export const LIST_OPTIONS = [
    {option: "measured_consumption", label: "Measured Consumption"},
    {option: "expected_consumption", label: "Expected Consumption"},
    {option: "outdoor", label: "OutdoorTemperature"}
]

export const OPTION_META={
    measured_consumption:{
        color:'#4a88bb'
    },
    expected_consumption:{
        color:"#bc5090"
    },
    outdoor:{
        color:"#4AB7BB",
    }
}

export const METRICS = {
    energy:{
        col:"heat_energy",
        unit:"kWh"
    },
    flow:{
        col:"volume",
        unit:'m³/h'
    },
    returntemp:{
        col:"returntemp",
        unit:"°C"
    },
    supplytemp:{
        col:"supplytemp",
        unit:"°C"
    },
    outdoor:{
        col:"t",
        unit:"°C"
    }
}

export const DISABLED_DURATION = new Set(['latest_12_months', 'current_calendar_year']);
export const DISABLED_COMPARE = new Set(['peak_power', 'epc_ret_temp_ft', 'volume_sum'])