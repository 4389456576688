const MDSL_URL = process.env.MDSL_URL;
const WEATHER_URL = process.env.WEATHER_URL;
const EP_URL = process.env.EP_URL
const EPO_URL = process.env.EPO_URL

export default {
    mdsl: MDSL_URL,
    weather: WEATHER_URL,
    ep: EP_URL,
    epo: EPO_URL
}