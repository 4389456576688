import React from 'react';
import {inject, observer} from 'mobx-react';
import ButtonDropdown from "../../components/ui/ButtonDropDown";
import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import CheckOption from "./CheckOption";
import {LIST_OPTIONS, OPTIONS} from './Constants';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2),
    },
})

@inject('sub')
@withStyles(styles)
@observer
class GraphSelector extends React.Component {
    onChangeGraphMetric = (value) => {
        const {sub} = this.props;
        sub.graph_metric = value;
    }

    render() {
        const {sub, classes} = this.props;

        return (
            <Box
                display="flex"
                height="100%"
                p={3}
                boxShadow={1}
                flexDirection="column">
                <ButtonDropdown
                    selectedValue={sub.graph_metric}
                    options={OPTIONS}
                    onChange={this.onChangeGraphMetric}
                />
                <FormControl component="fieldset" className={classes.formControl}>
                    <FormGroup>
                        {LIST_OPTIONS.map(lbl => <CheckOption key={lbl.option} lbl={lbl}/>)}
                    </FormGroup>
                </FormControl>
            </Box>
        );
    }
}

export default GraphSelector;