import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        //maxWidth: 'none'
        //flexGrow: 1,
        boxShadow: '0 1px 3px 0 #00000029',
    },
    scrollable: {
        flexGrow: 1
    }
})

@withStyles(styles)
class UtfTabs extends React.Component {

    render() {
        const {children, ...restProps} = this.props;

        return (
            <Tabs  {...restProps}>
                {children}
            </Tabs>);
    }
}

export default UtfTabs;