import React from 'react';
import {inject, observer} from 'mobx-react';
import {withStyles} from '@material-ui/core/styles';
import HeadingBar from "../../components/app/HeadingBar";
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ChevronLeft';
import {RouterState} from "mobx-state-router";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {formatNumber} from '../../core/utils';
import {FormattedMessage} from 'react-intl';

const styles = theme => ({
    ico: {
        padding: 0
    }
});

@inject('sub', 'rootStore')
@withStyles(styles)
@observer
class Title extends React.Component {
    goBack = () => {
        const {rootStore: {routerStore}} = this.props;
        routerStore.goTo(new RouterState("dash"));
    }

    render() {
        const {sub, classes, rootStore: {routerStore}} = this.props;
        const {params} = routerStore.routerState;

        return (
            <HeadingBar>
                <IconButton
                    color="primary"
                    padding="none"
                    className={classes.ico}
                    onClick={this.goBack}>
                    <BackIcon fontSize="default"/>
                </IconButton>
                <Typography variant="h5">
                    {sub.detail.address}
                </Typography>
                <Typography variant="h6" style={{marginLeft: 8, color: '#747b80'}}>
                    ({params.id})
                </Typography>
                <Box flexGrow={1} display="flex"/>
                <Typography variant="h6" style={{color: '#747b80'}}>
                    <FormattedMessage id="consumption"/>:
                </Typography>
                <Typography variant="subtitle2" color="secondary" style={{marginLeft: 8}}>
                    {formatNumber(sub.detail.normal_year)} kWh/year
                </Typography>
                <Typography variant="h6" style={{color: '#747b80', marginLeft: 16}}>
                    <FormattedMessage id="return_temp_full"/>:
                </Typography>
                <Typography variant="subtitle2" color="secondary" style={{marginLeft: 8}}>
                    {formatNumber(sub.detail.return_temp)} °C
                </Typography>

                <Typography variant="h6" style={{color: '#747b80', marginLeft: 16}}>
                    <FormattedMessage id="design_load"/>:
                </Typography>
                <Typography variant="subtitle2" color="secondary" style={{marginLeft: 8}}>
                    {formatNumber(sub.detail.design_load)} kW
                </Typography>
            </HeadingBar>)
    }
}

export default Title;