import React from 'react';
import Box from "@material-ui/core/Box";
import GraphSelector from "./GraphSelector";
import {disposeOnUnmount, inject, observer} from "mobx-react";
import {reaction} from "mobx";
import HistogramChart from "../../components/analytics/Graph";

@inject('ui', 'sub')
@observer
class DetailsContainer extends React.Component {

    componentDidMount() {
        const {sub} = this.props;
        disposeOnUnmount(
            this,
            reaction(
                () => {
                    return [sub.substation, sub.date_min, sub.date_max]
                },
                async () => {
                    await sub.getGraphData()
                }
            )
        )
        disposeOnUnmount(this,
            reaction(
                ()=> {
                    let rs = [];
                    for (let k of Object.keys(sub.graph_metric_option)) {
                        rs.push(sub.graph_metric_option[k])
                    }
                    rs.push("gm")
                    rs.push(sub.graph_metric)
                    if(sub.fetchedGraphData){
                        rs.push(Object.keys(sub.fetchedGraphData))
                    }
                    return rs;
                },
                ()=>{
                    sub.processGraphData()
                }
            ))
    }

    render() {
        const {ui, sub} = this.props;
        return (
            <>
                <Box bgcolor="white" width='100%' boxShadow={1}>
                    {ui.windowWidth
                    && <HistogramChart
                        xAxis={{}}
                        chartOptions={{
                            height: 660,
                            type: 'line',
                            width: ui.windowWidth > 0 ? ui.windowWidth - 480 : 600
                        }}
                        rangeSelector={{
                            enabled: true,
                            verticalAlign: 'bottom',
                            x: 0,
                            y: 0
                        }}
                        yAxis={sub.graphYAxis}
                        chartData={sub.graphSeries}
                        chartCompOptions={{constructorType: 'stockChart',immutable:true}}
                    />}
                </Box>
                <Box width={380} ml={1} bgcolor="white">
                    <GraphSelector/>
                </Box>
            </>
        )
    }
}

export default DetailsContainer;