import React from 'react';
import {observer} from 'mobx-react';
import {action, observable} from 'mobx';

import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {FormattedMessage} from "react-intl";

const styles = theme => ({
    root: {
        textTransform: 'none',
        borderColor: theme.palette.primary.main,
        borderRadius: '2px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    icon: {
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(2)
    },
    label: {
        display: "flex",
        justifyContent: "space-between"
    }
});

@withStyles(styles)
@observer
class ButtonDropdown extends React.Component {
    @observable selectedValue;
    @observable menuOpen = false;

    constructor(props) {
        super(props);
        this.anchorRef = React.createRef();
        this.btnWidth = 200;
        this.options = props.options;

        this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.selectedValue = this.options.get(props.selectedValue);
    }

    @action.bound
    toggleMenuOpen() {
        this.menuOpen = !this.menuOpen;
    }

    @action.bound
    handleClose() {
        this.menuOpen = false;
    }

    @action.bound
    handleSelectItem(event, value) {
        const {onChange} = this.props;
        this.selectedValue = value;
        onChange(value);
        this.menuOpen = false;
    }

    componentDidMount() {
        this.btnWidth = this.anchorRef.current.getBoundingClientRect().width;
    }

    render() {
        const {classes, options, selectedValue, disabled = new Set(), color = 'inherit', optionPrefix, ...props} = this.props;
        return (<>
            <Button
                ref={this.anchorRef}
                onClick={this.toggleMenuOpen}
                className={classes.root}
                classes={{label: classes.label}}
                variant="outlined"
                {...props}
                color="inherit"
                endIcon={<ArrowDropDownIcon className={classes.icon}/>}
            >
                <Typography variant="body1">
                    {optionPrefix} {<FormattedMessage id={selectedValue}/>}
                </Typography>
            </Button>
            <Popper
                open={this.menuOpen}
                anchorEl={this.anchorRef.current}
                role={undefined}
                transition
                disablePortal
                style={{zIndex: 1, minWidth: this.btnWidth}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <MenuList id="split-button-menu" color={color}>
                                    {Array.from(options.entries()).map((option) => (
                                        <MenuItem
                                            key={option[0]}
                                            selected={option[0] === this.selectedValue}
                                            disabled={disabled.has(option[0])}
                                            onClick={event => this.handleSelectItem(event, option[0])}
                                        >
                                            <Typography variant="body2" color="inherit">
                                                {optionPrefix} {<FormattedMessage id={option[0]} />}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>)
    }
}

export default ButtonDropdown;