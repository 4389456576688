import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {formatNumber} from '../../core/utils';
import {isVal} from '../../stores/utils';

const styles = theme => ({
    nodata: {
        color: theme.palette.grey.blue28
    }
});

@withStyles(styles)
class NumericalCell extends Component {
    render() {
        const {row, colname, classes} = this.props;
        const divPositive = row[colname];
        if (isVal(divPositive)) {
            return (
                <Typography component="span" variant="body2">
                    {formatNumber(divPositive)}
                </Typography>)
        } else {
            return <Typography component="span" variant="body2" className={classes.nodata}>NoData</Typography>
        }

    }
}

export default NumericalCell;